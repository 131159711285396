import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {calculateImageSize} from "../../const.js"

const CustomizeProduct = ({ customizeDetails, coloums }) => {
  const width = useRef(window.innerWidth);
  const [productType, setProductType] = useState(false);

  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });

  const { getSingleProductData } = useSelector((state) => state.commonReducer);

  const customerImg = customizeDetails?.[0]
    ? customizeDetails?.[0]?.customerImg
    : getSingleProductData?.customizeDetails?.[
        getSingleProductData?.defaultSample
      ]?.customerImg;
  const inputFields = customizeDetails?.[0]
    ? customizeDetails?.[0]?.inputFields
    : getSingleProductData?.customizeDetails?.[
        getSingleProductData?.defaultSample
      ]?.inputFields;
  const customBg = customizeDetails?.[0]?.productbgColor
    ? customizeDetails?.[0]?.productbgColor
    : getSingleProductData?.customizeDetails?.[
        getSingleProductData?.defaultSample
      ]?.productbgColor;
  const bgCustomImage = customizeDetails?.[0]?.productImg
    ? customizeDetails?.[0]?.productImg
    : getSingleProductData?.customizeDetails?.[
        getSingleProductData?.defaultSample
      ]?.productImg;

  const [images, setImages] = useState([]);
  //customize image setting
  const [customizeHeight, setCustomizeHeight] = useState("0px");
  const [customizeWidth, setCustomizeWidth] = useState("0px");
  useEffect(() => {
    const { cheight, cwidth } = calculateImageSize(width.current, coloums);
    setCustomizeHeight(cheight);
    setCustomizeWidth(cwidth);
  }, [width.current]);
  //onload customize img
  useEffect(() => {
    const loadImage = () => {
      if (!getSingleProductData) return;
      const imageUrl = !productType
        ? getSingleProductData.productImg
        : getSingleProductData?.customizeDetails?.[
            getSingleProductData?.defaultSample
          ]?.productImg;
      if (!imageUrl) return;
      const img = new Image();
      img.src = `/${imageUrl}`;
      img.onload = () => {
        setImageDimensions({
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
      };
    };

    loadImage();
  }, [getSingleProductData, productType]);

  //on load customize image
  useEffect(() => {
    const loadImage = () => {
      if (!getSingleProductData) return;

      const imageUrl = !productType
        ? getSingleProductData.productImg
        : getSingleProductData?.customizeDetails?.[
            getSingleProductData?.defaultSample
          ]?.productImg;

      if (!imageUrl) return;

      const img = new Image();
      img.src = `/${imageUrl}`;
      img.onload = () => {
        setImageDimensions({
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
      };
    };

    loadImage();
  }, [getSingleProductData, productType]);

  useEffect(() => {
    const loadBgImage = () => {
      if (!bgCustomImage) return;

      const img = new Image();
      img.src = `/${bgCustomImage}`;
      img.onload = () => {
        setImageDimensions({
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
      };
    };

    loadBgImage();
  }, [bgCustomImage]);

  return (
    <>
      <div
        style={{
          background: `${customBg}`,
          width : customizeWidth,
          height : customizeHeight,
          // width: `${
          //   width.current > 500 || capture
          //     ? imageDimensions.width
          //     : imageDimensions.width / 2
          // }px`,
          // height: `${
          //   width.current > 500 || capture
          //     ? imageDimensions.height
          //     : imageDimensions.height / 2
          // }px`,
          position: "relative",
        }}
      >
        {/* Image behind the name-slip */}
        {!!customerImg &&
          customerImg?.map((img, index) => {
            let imageSrc =
              localStorage.getItem(img.name) || `/${img?.imageUrl}`;
            return (
              <div
                key={index}
                className="overflow-hidden"
                style={{
                  position: "absolute",
                  userSelect: "auto",
                  width: img.width.endsWith("px") ? img.width : `${img.width}px`,
                  height: img.width.endsWith("px") ? img.height : `${img.height}px`,
                  // width:
                  //   width.current > 500 || capture
                  //     ? img.width.endsWith("px")
                  //       ? img.width
                  //       : `${img.width}px`
                  //     : "30%",
                  // height:
                  //   width.current > 500 || capture
                  //     ? img.height.endsWith("px")
                  //       ? img.height
                  //       : `${img.height}px`
                  //     : "40%",
                  // width : customizeWidth,
                  // height : customizeHeight,
                  display: "inline-block",
                  cursor: "move",
                  zIndex: 1,
                  // transform:
                  //   width.current > 500 || capture
                  //     ? `translate(${img.x}px, ${img.y}px)`
                  //     : `translate(${img.x / 2}px, ${img.y / 2}px)`,
                  transform:`translate(${img.x}px, ${img.y}px)`,
                  boxSizing: "border-box",
                  flexShrink: 0,
                }}
              >
                <img
                  className="w-100"
                  src={imageSrc}
                  alt={images?.[index]?._id}
                  style={{
                    position: "relative",
                    // top: `${imageTopBottom}px`,
                    // left: `${imageLeftRight}px`,
                    // transform: `rotate(${rotation}deg) scale(${imageScale})`,
                    objectFit: "contain",
                    height: "100%",
                    width: "100%",
                  }}
                />
              </div>
            );
          })}
        <div
          className="name-slip"
          style={{
            background: `url(/${bgCustomImage})`,
            width : customizeWidth,
            height : customizeHeight,
            // width: `${
            //   width.current > 500 || capture
            //     ? imageDimensions.width
            //     : imageDimensions.width / 2
            // }px`,
            // height: `${
            //   width.current > 500 || capture
            //     ? imageDimensions.height
            //     : imageDimensions.height / 2
            // }px`,
            backgroundSize: `cover`,
            backgroundRepeat: `no-repeat`,
            backgroundSize: "contain",
            overflow: "hidden",
            position: "relative",
            zIndex: 2,
          }}
        >
          {/* input box start */}
          {!!inputFields &&
            inputFields.map((field, index) => {
              const storedValue = localStorage.getItem(field?.label?.slug);
              let fieldValue = storedValue !== null ? storedValue : field.value;
              const fontSize = parseFloat(field?.style?.fontSize);
              // const adjustedFontSize =
              //   width.current > 500 || capture ? fontSize : fontSize / 2;
              // const adjustedX =
              //   (width.current > 500 || capture ? field?.x : field?.x / 2) ||
              //   index * 20;
              // const adjustedY =
              //   (width.current > 500 || capture ? field?.y : field?.y / 2) ||
              //   index * 20;

              return (
                <div
                  key={index}
                  className="input react-draggable react-draggable-dragged"
                  style={{
                    position: "absolute",
                    userSelect: "auto",
                    color: field?.style?.color,
                    fontFamily: field?.style?.fontFamily,
                    fontWeight: field?.style?.fontWeight,
                    textAlign: field?.style?.textAlignment,
                    transform:  `rotate(${field?.style?.rotation || 0}deg)`,
                    fontSize: `${fontSize}px`,
                    width:  field?.width?.endsWith("px") ? field?.width : `${field?.width}px`,
                    // width: `${
                    //   width.current > 500 || capture
                    //     ? field?.width
                    //     : field?.width / 2
                    // }px`,
                    display: "inline-block",
                    top: `${field?.y}px`,
                    left: `${field?.x}px`,
                    cursor: "move",
                    maxWidth: "9.0072e+15px",
                    maxHeight: "9.0072e+15px",
                    boxSizing: "border-box",
                    flexShrink: "0",
                    // height: `${
                    //   width.current > 500 || capture
                    //     ? field?.height
                    //     : field?.height / 2
                    // }px`,
                    zIndex: 3,
                  }}
                >
                  {fieldValue}
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default CustomizeProduct;

import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import domtoimage from "dom-to-image";
import CommonHeader from "./CommonHeader";
import uploadimage from "../../images/uploadimage.jpg";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import {
  addCart,
  detailsCustomizeProductsImg,
  getCartlist,
  getSingleProduct,
} from "../../reducers/commonReducer";
import { useNavigate, useParams } from "react-router-dom";
import ProductTab from "./ProductTab";
import { checkout, phoneVerify, productRating } from "../../const";
import {
  getProductReviews,
  singleOrderItem,
  updateOrderItem,
} from "../../reducers/orderDetailsReducer";
import PinCodeServiceAreaVerify from "../Main/user/PinCodeServiceAreaVerify";
import ProductMultiSlider from "./ProductMultiSlider";

const ProductsDetails = () => {
  const width = useRef(window.innerWidth);
  const divRef = useRef(null);
  let params = useParams();
  const queryParameters = new URLSearchParams(window.location.search);
  const orderid = queryParameters.get("orderid");
  const itemIndex = queryParameters.get("itemIndex");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [productType, setProductType] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [imageScale, setImageScale] = useState(1);
  const [imageLeftRight, setImageLeftRight] = useState(0);
  const [imageTopBottom, setImageTopBottom] = useState(0);
  const [capture, setCapture] = useState(false);
  const [activeSample, setActiveSample] = useState(0);
  const [orderQty, setOrderQty] = useState(1);
  const [inputFields, setInputFields] = useState([]);
  const [isLogin, setIsLogin] = useState(0);
  const [productAttributes, setProductAttributes] = useState();
  const [productAttributesPrice, setProductAttributesPrice] = useState();
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    dispatch(
      getSingleProduct({
        slug: params?.slug,
      })
    );
    if (orderid) {
      dispatch(singleOrderItem({ id: orderid, index: +itemIndex }));
    }
  }, []);
  const { getSingleProductData, customizeProductsImgDetails } = useSelector(
    (state) => state.commonReducer
  );

// const customerImg = customizeProductsImgDetails?.customerImg
//   ? customizeProductsImgDetails?.customerImg
//   : getSingleProductData?.customizeDetails?.[0]?.customerImg;
const customerImg = productType
  ? getSingleProductData?.customizeDetails?.[getSingleProductData?.defaultSample]?.customerImg
  : customizeProductsImgDetails?.customerImg ||
    getSingleProductData?.customizeDetails?.[getSingleProductData?.productType === "customize" ? 0 : getSingleProductData?.defaultImage]?.customerImg;
// const customBg = customizeProductsImgDetails?.productbgColor
//   ? customizeProductsImgDetails?.productbgColor
//   : getSingleProductData?.customizeDetails?.[0]?.productbgColor;
const customBg = productType
  ? getSingleProductData?.customizeDetails?.[getSingleProductData?.defaultSample]?.productbgColor
  : customizeProductsImgDetails?.productbgColor ||
    getSingleProductData?.customizeDetails?.[getSingleProductData?.productType === "customize" ? 0 : getSingleProductData?.defaultImage]?.productbgColor;
// const bgCustomImage = customizeProductsImgDetails?.productImg
//   ? customizeProductsImgDetails?.productImg
//   : getSingleProductData?.customizeDetails?.[0]?.productImg;
const bgCustomImage = productType
  ? getSingleProductData?.customizeDetails?.[getSingleProductData?.defaultSample]?.productImg
  : customizeProductsImgDetails?.productImg ||
    getSingleProductData?.customizeDetails?.[getSingleProductData?.productType === "customize" ? 0 : getSingleProductData?.defaultImage]?.productImg;

  //update order start
  // useEffect(() => {
  //   if (singleOrderItemData) {
  //     setInputFields(singleOrderItemData?.customizeDetails?.inputFields);
  //     setImages(singleOrderItemData?.customizeDetails?.userimg);
  //     setOrderQty(singleOrderItemData?.quantity);
  //   }
  // }, [singleOrderItemData]);
  //update order end
  useEffect(() => {
    if (getSingleProductData?.id) {
      dispatch(getProductReviews(getSingleProductData?.id));
    }
    setActiveSample(0);
  }, [getSingleProductData]);
  //tab product details
  useEffect(() => {
    dispatch(
      detailsCustomizeProductsImg({
        productid: getSingleProductData?.id,
        index: 0,
      })
    );
  },[])
  const [images, setImages] = useState([]);
  //upload user images
  const handleFileChangeOld = (files, index, imgName) => {
    if (files) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result;
        localStorage.setItem(imgName, base64);
        const newImage = {
          id: Date.now(),
          imageUrl: base64,
        };
        setImages((prevImages) => {
          const updatedImages = [...prevImages];
          updatedImages[index] = newImage;
          return updatedImages;
        });
      };
      reader.readAsDataURL(file);
      
    }
  };

  const handleFileChange = (files, index, imgName) => {
    if (files) {
      const file = files[0];

      const maxSize = 10 * 1024 * 1024; // 10MB
      if (file.size > maxSize) {
        alert('File size exceeds 10MB limit. Please upload a smaller file.');
        return; // Stop further processing
      }

      const reader = new FileReader();
      
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
        
        img.onload = () => {
          // Create a canvas element to compress the image
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
  
          // Set the desired max width for the compressed image
          const maxWidth = 800;
          const scaleSize = maxWidth / img.width;
          canvas.width = maxWidth;
          canvas.height = img.height * scaleSize;
  
          // Draw the image on the canvas with the new dimensions
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  
          // Convert the canvas to a compressed Base64 string (JPEG format)
          const compressedBase64 = canvas.toDataURL('image/jpeg', 0.7); // 70% quality
  
          // Save the compressed image to localStorage
          localStorage.setItem(imgName, compressedBase64);
  
          // Create a new image object with the compressed data
          const newImage = {
            id: Date.now(),
            imageUrl: compressedBase64,
          };
  
          // Update state with the new compressed image
          setImages((prevImages) => {
            const updatedImages = [...prevImages];
            updatedImages[index] = newImage;
            return updatedImages;
          });
        };
      };
  
      reader.readAsDataURL(file); // Read the file as a Data URL
    }
  };
  


  const handleDeleteImage = (index) => {
    setImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages[index] = null;
      return updatedImages;
    });
  };
  //islogin status
  const handleSignupStatusChange = (status) => {
    setIsLogin(status);
  };

  // input fields
  useEffect(() => {
    // const initialInputFields = customizeProductsImgDetails?.inputFields
    //   ? customizeProductsImgDetails.inputFields
    //   : getSingleProductData?.customizeDetails?.[0]?.inputFields || [];
    const initialInputFields = productType
  ? getSingleProductData?.customizeDetails?.[getSingleProductData?.defaultSample]?.inputFields 
  : customizeProductsImgDetails?.inputFields ||
    getSingleProductData?.customizeDetails?.[0]?.inputFields || [];
  // Update input fields with values from localStorage if available
    const updatedInputFields = initialInputFields.map((field) => {
      const storedValue = localStorage.getItem(field.label?.slug);
      return {
        ...field,
        value: storedValue !== null ? storedValue : field.value,
      };
    });
    setInputFields(updatedInputFields);
  }, [customizeProductsImgDetails, productType, getSingleProductData?.customizeDetails]);

  const handleChange = (index, event, label) => {
    const newInputFields = inputFields.map((field, i) =>
      i === index ? { ...field, value: event.target.value } : field
    );
    setInputFields(newInputFields);
    localStorage.setItem(`${label?.slug}`, event.target.value);
  };
  //onload customize img
  useEffect(() => {
    const loadImage = () => {
      if (!getSingleProductData) return;
      const imageUrl = !productType
        ? getSingleProductData.productImg
        : getSingleProductData?.customizeDetails?.[
            getSingleProductData?.defaultSample
          ]?.productImg;
      if (!imageUrl) return;
      const img = new Image();
      img.src = `/${imageUrl}`;
      img.onload = () => {
        setImageDimensions({
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
      };
    };

    loadImage();
  }, [getSingleProductData, productType]);

  //on load customize image
  useEffect(() => {
    const loadImage = () => {
      if (!getSingleProductData) return;
  
      const imageUrl = !productType
        ? getSingleProductData.productImg
        : getSingleProductData?.customizeDetails?.[
            getSingleProductData?.defaultSample
          ]?.productImg;
  
      if (!imageUrl) return;
  
      const img = new Image();
      img.src = `/${imageUrl}`;
      img.onload = () => {
        setImageDimensions({
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
      };
    };
  
    loadImage();
  }, [getSingleProductData, productType]);
  
  useEffect(() => {
    const loadBgImage = () => {
      if (!bgCustomImage) return;
  
      const img = new Image();
      img.src = `/${bgCustomImage}`;
      img.onload = () => {
        setImageDimensions({
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
      };
    };
  
    loadBgImage();
  }, [bgCustomImage]);

  const addToCart = async (typeOfProduct) => {
    const requiredFields = inputFields.filter((input) => input.required);
    const isValid = requiredFields.every((input) => input.value.trim() !== "");
    if (!isValid) {
      alert("Please fill in all required fields.");
      setCapture(false);
      return;
    }
    let dataUrl;
    if(typeOfProduct === "customize"){
       setCapture(true);
      const div = divRef.current;
      div.style.width = `${imageDimensions.width}px`;
      div.style.height = `${imageDimensions.height}px`;
      dataUrl = await domtoimage.toPng(div, { width: imageDimensions.width, height: imageDimensions.height });
      if(dataUrl){
        setCapture(false);
      }
    }
  
    let apiResp;
    const cartList = () =>{
      if(apiResp?.payload.status){
        dispatch(getCartlist())
        window.location.reload();
      }
    }
    if (!localStorage.getItem("x-auth-token")) {
        localStorage.setItem("mergeimg", dataUrl);
        localStorage.setItem("product", getSingleProductData?.id);
        localStorage.setItem("attributeid", productAttributes);
        localStorage.setItem("quantity", orderQty);
        localStorage.setItem("userimg", JSON.stringify(images));
        localStorage.setItem("inputFields", JSON.stringify(inputFields));
        navigate(phoneVerify);
    }else if (typeOfProduct === "normal" && orderid) {
      apiResp = await dispatch(
        updateOrderItem({
          product: getSingleProductData?.id,
          quantity: orderQty,
          id: orderid,
          index: +itemIndex,
        })
      );
      cartList();
    } else if (typeOfProduct === "normal") {
      localStorage.getItem("x-auth-token")
        ? (apiResp = await dispatch(
            addCart({
              product: getSingleProductData?.id,
              quantity: orderQty,
              attributeid: productAttributes,
            })
          ))
        : navigate(phoneVerify);
        cartList();
    } else if (typeOfProduct === "limitedEdition" && orderid) {
      apiResp = await dispatch(
        updateOrderItem({
          product: getSingleProductData?.id,
          quantity: orderQty,
          inputFields,
          id: orderid,
          index: +itemIndex,
        })
      );
      cartList();
    } else if (typeOfProduct === "limitedEdition") {
      localStorage.getItem("x-auth-token")
        ? (apiResp = await dispatch(
            addCart({
              product: getSingleProductData?.id,
              quantity: orderQty,
              inputFields,
              attributeid: productAttributes,
            })
          ))
        : navigate(phoneVerify);
        cartList();
    } else if (typeOfProduct === "customize" && orderid) {
      apiResp = await dispatch(
        updateOrderItem({
          mergeimg: dataUrl,
          product: getSingleProductData?.id,
          quantity: orderQty,
          userimg: images,
          inputFields,
          id: orderid,
          index: +itemIndex,
        })
      );
      cartList();
    } else if (typeOfProduct === "customize") {
      localStorage.getItem("x-auth-token")
        ? (apiResp = await dispatch(
            addCart({
              mergeimg: dataUrl,
              product: getSingleProductData?.id,
              quantity: orderQty,
              userimg: images,
              inputFields,
              index: +itemIndex,
            })
          ))
        : navigate(phoneVerify);
        cartList();
    } else {
       alert("Server Error Please Wait...")
    }
  };


  return (
    <>
      <CommonHeader />
      <section className={width.current > 500 ? "mt-5 mb-5" : "mt-2 mb-2"}>
        <div className="container-fluid">
          <Row>
            <Col md={6} sm={12} xs={12}>
              {getSingleProductData?.productType !== "customize" ? (
                <ProductMultiSlider
                  images={getSingleProductData?.images}
                  productType={productType}
                  customizeSlide={getSingleProductData?.customizeDetails}
                />
              ) : (
                <div
                  ref={divRef}
                  style={{
                    background: `${customBg}`,
                    width: `${
                      width.current > 500 || capture
                        ? imageDimensions.width
                        : imageDimensions.width / 2
                    }px`,
                    height: `${
                      width.current > 500 || capture
                        ? imageDimensions.height
                        : imageDimensions.height / 2
                    }px`,
                    maxHeight:"600px",
                    maxWidth:"600px",
                    position: "relative",
                  }}
                >
                  {/* Image behind the name-slip */}
                  {!!customerImg &&
                    customerImg?.map((img, index) => { 
                      let imageSrc = localStorage.getItem(img.name) || `/${img?.imageUrl}`;
                      // let imageSrc = localStorage.getItem(img.name) || images?.[index]?.imageUrl;
                      return (
                        <div
                          key={index}
                          className="overflow-hidden"
                          style={{
                            position: "absolute",
                            userSelect: "auto",
                            width:
                              width.current > 500 || capture
                                ? img.width.endsWith("px")
                                  ? img.width
                                  : `${img.width}px`
                                : "30%",
                            height:
                              width.current > 500 || capture
                                ? img.height.endsWith("px")
                                  ? img.height
                                  : `${img.height}px`
                                : "40%",
                            display: "inline-block",
                            cursor: "move",
                            zIndex: 1,
                            transform:
                              width.current > 500 || capture
                                ? `translate(${img.x}px, ${img.y}px)`
                                : `translate(${img.x / 2.2}px, ${img.y / 2.5}px)`,
                            boxSizing: "border-box",
                            flexShrink: 0,
                          }}
                        >
                          <img
                            className="w-100"
                            src={imageSrc}
                            alt={images?.[index]?._id}
                            style={{
                              position: "relative",
                              top: `${imageTopBottom}px`,
                              left: `${imageLeftRight}px`,
                              transform: `rotate(${rotation}deg) scale(${imageScale})`,
                              objectFit: "contain",
                              height: "100%",
                              width: "100%",
                            }}
                          />
                        </div>
                      );
                    })}
                  <div
                    className="name-slip name-slip-bg product-bg-container"
                    style={{
                      background: `url(/${
                        bgCustomImage
                        // !productType
                        //   ? getSingleProductData.productImg
                        //   : bgCustomImage
                      })`,
                      width: `${
                        width.current > 500 || capture
                          ? imageDimensions.width
                          : imageDimensions.width / 2
                      }px`,
                      height: `${
                        width.current > 500 || capture
                          ? imageDimensions.height
                          : imageDimensions.height / 2
                      }px`,
                      backgroundSize: `cover`,
                      backgroundRepeat: `no-repeat`,
                      maxHeight:"600px",
                      maxWidth:"600px",
                      backgroundSize: "contain",
                      overflow: "hidden",
                      position: "relative",
                      zIndex: 2,
                    }}
                  >
                    {/* input box start */}
                    {!!inputFields &&
                      inputFields.map((field, index) => {
                        let fieldValue =
                          localStorage.getItem(field?.label?.slug) || field.value;
                        const fontSize = parseFloat(field?.style?.fontSize);
                        const adjustedFontSize =
                          width.current > 500 || capture
                            ? fontSize
                            : fontSize / 2;
                        // Calculate position with fallbacks to prevent overlap
                        const adjustedX =
                          (width.current > 500 || capture
                            ? field?.x
                            : field?.x / 2) || index * 20;
                        const adjustedY =
                          (width.current > 500 || capture
                            ? field?.y
                            : field?.y / 2) || index * 20;
                        //input width
                        const inputWidth = field.width.endsWith("px")
                            ? parseInt(field.width, 10)
                            : parseInt(field.width, 10);
                        const calculatedWidth = width.current > 500 || capture
                            ? inputWidth
                            : inputWidth / 2;
                        const finalWidth = `${calculatedWidth}px`;
                       
                        return (
                          <div
                            key={index}
                            className="input react-draggable react-draggable-dragged"
                            style={{
                              position: "absolute",
                              userSelect: "auto",
                              color: field?.style?.color,
                              fontFamily: field?.style?.fontFamily,
                              fontWeight: field?.style?.fontWeight,
                              textAlign: field?.style?.textAlignment,
                              transform:  `rotate(${field?.style?.rotation || 0}deg)`,
                              fontSize: `${adjustedFontSize}px`,
                              // width: `${
                              //   width.current > 500 || capture
                              //     ? field?.width
                              //     : field?.width / 2
                              // }px`,
                              width: finalWidth,
                              display: "inline-block",
                              top: `${adjustedY}px`,
                              left: `${adjustedX}px`,
                              cursor: "move",
                              maxWidth: "9.0072e+15px",
                              maxHeight: "9.0072e+15px",
                              boxSizing: "border-box",
                              flexShrink: "0",
                              overflow: "hidden",
                              height: `${
                                width.current > 500 || capture
                                  ? field?.height
                                  : field?.height / 2
                              }px`,
                              zIndex: 3,
                            }}
                          >
                            {fieldValue}
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
              {(!productType && getSingleProductData?.productType === "customize") && (
                  <Row className="mt-3">
                    {!!getSingleProductData?.customizeDetails &&
                      getSingleProductData?.customizeDetails?.map(
                        (sampleimg, index) => (
                          getSingleProductData?.defaultSample !== index && <Col key={index} md={2} sm={2} xs={2} className={`mt-2 img-tabs shadow p-2 ${(activeSample === index) && `bg-success p-1`}`}>
                           <img
                             onClick={() => {
                              dispatch(
                                detailsCustomizeProductsImg({
                                  productid: getSingleProductData?.id,
                                  index: index + 1,
                                })
                              );
                              setActiveSample(index);
                            }}
                              className="text-center"
                              src={`/${sampleimg?.productImg}`}
                              alt={sampleimg?.name}
                            />
                          </Col>
                        )
                      )}
                  </Row>
                )}
            </Col>
            <Col md={6} sm={12} xs={12} className="p-2">
              {!productType && <Row>
                <h4 className="fw-bold product-details-name">
                  {getSingleProductData?.name}
                </h4>
                <hr />
                <p className="mb-1">{productRating(5)}</p>
                <h3 className="mb-0 font-weight-semibold product__price text-success">
                  <span className="discount-price text-danger">
                    Rs.{getSingleProductData?.mrp}
                  </span>{" "}
                  Rs.
                  <b>
                    {(productAttributesPrice
                      ? productAttributesPrice
                      : getSingleProductData?.sale) * orderQty}
                  </b>
                </h3>
                {!productType && (
                  <>
                    <p className="text-secondColor f-16 mb-0">
                      <b>Tax & Shipping Included. No Hidden Charges.</b>
                    </p>
                    <p className="f-16 mb-0">
                      <b>
                        Vendor:{" "}
                        {`${getSingleProductData?.vendor?.firstName} ${getSingleProductData?.vendor?.lastName}`}
                      </b>
                    </p>
                    <p className="text-secondColor f-16 mb-0">
                      <b>Dispatch within 3 to 7 working days.</b>
                    </p>
                    {getSingleProductData?.description && (
                      <Row
                        dangerouslySetInnerHTML={{
                          __html: getSingleProductData?.description
                            ? getSingleProductData.description.substring(
                                0,
                                150
                              ) +
                              (getSingleProductData.description.length > 150
                                ? "..."
                                : "")
                            : "",
                        }}
                      />
                    )}
                  </>
                )}
              </Row>}
              <Row>
                <Col md={12} className="mt-2">
                  <PinCodeServiceAreaVerify
                    onSignupStatusChange={handleSignupStatusChange}
                  />
                </Col>
                {((getSingleProductData?.productType === "customize") && !productType) && (
                  <Col md={12}>
                    <p className="mt-2">
                      <Button
                        onClick={() => setProductType(true)}
                        className="theme-btn w-100"
                      >
                        Personalize & Customize
                      </Button>
                    </p>
                  </Col>
                )}
              </Row>
              {((productType && (getSingleProductData?.productType === "customize")) || (getSingleProductData?.productType === "limitedEdition")) && (
                <>
                  {getSingleProductData?.attributes?.[0]?.value && (
                    <Row className="mb-3">
                      <Col>
                        <Form.Group>
                          <Form.Label>Product Attribute</Form.Label>
                          <Form.Control
                            as="select"
                            value={productAttributes}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              setProductAttributesPrice(
                                e.target.selectedOptions[0].getAttribute(
                                  "data-value"
                                )
                              );
                              setProductAttributes(selectedValue);
                            }}
                          >
                            <option value="">Select Attribute</option>
                            {getSingleProductData?.attributes?.map(
                              (attribute, aIndex) => (
                                <option
                                  key={aIndex}
                                  data-value={attribute?.value}
                                  value={attribute?.attributeid?.id}
                                >
                                  {attribute?.attributeid?.name} (
                                  {attribute?.value}/-)
                                </option>
                              )
                            )}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                  <Row className="mb-3">
                    {!!inputFields &&
                      inputFields?.map((fields, index) => {
                        let fieldValue = localStorage.getItem(fields?.label?.slug) || "";
                        return (
                          <Col
                            md={6}
                            sm={6}
                            xs={6}
                            className={width.current > 500 ? "p-2" : ""}
                          >
                            <label>{fields?.name}</label>
                            <input
                              key={index}
                              className={`${
                                !fieldValue > 0 &&
                                fields?.required &&
                                "border-danger"
                              }`}
                              type="text"
                              name={fields?.slug}
                              maxLength={fields.maxLength}
                              value={fieldValue}
                              required={fields?.required}
                              onChange={(e) =>
                                handleChange(index, e, fields?.label)
                              }
                              style={{
                                width: "100%",
                                color: "rgb(0, 0, 0)",
                                fontSize: "16px",
                                fontFamily: "Arial, sans-serif",
                                fontWeight: "normal",
                              }}
                            />
                            {!fieldValue > 0 && fields?.required && (
                              <p className="mb-0 text-danger">
                                This field is Required.
                              </p>
                            )}
                          </Col>
                        );
                      })}
                  </Row>
                  {!!customerImg && customerImg?.length > 0 && (
                    <Row>
                      <Col md={6} sm={6} xs={6}>
                        <Row>
                          {customerImg?.map((item, index) => {
                            const imageSrc =
                              images[index]?.imageUrl || uploadimage;
                            return (
                              <Col sm={12} xs={12} className="mb-3" key={index}>
                                <input
                                  type="file"
                                  accept="image/png, image/jpeg, image/jpg, image/webp"
                                  onChange={(e) =>
                                    handleFileChange(e.target.files, index, item?.name)
                                  }
                                  style={{ display: "none" }}
                                  id={`image-input-${index}`}
                                />
                                <div style={{ position: "relative" }}>
                                  <img
                                    className="w-100"
                                    onClick={() =>
                                      document
                                        .getElementById(`image-input-${index}`)
                                        .click()
                                    }
                                    src={imageSrc}
                                    alt="Upload"
                                  />
                                  {!images[index]?.imageUrl && (
                                    <p className="mb-0 text-danger">
                                      <b>Please upload image is Required.</b>
                                    </p>
                                  )}
                                  {images[index] && (
                                    <Button
                                      variant="danger"
                                      size="sm"
                                      style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "10px",
                                      }}
                                      onClick={() => handleDeleteImage(index)}
                                    >
                                      <i className="bi bi-trash3"></i>
                                    </Button>
                                  )}
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </Col>
                      <Col
                        md={6}
                        sm={6}
                        xs={6}
                        className="text-center overflow-hidden"
                      >
                        <Row>
                          <Col className="mt-2" md={3} sm={3} xs={3}>
                            <Button
                              className={
                                width.current > 500
                                  ? `theme-btn`
                                  : `theme-btn px-1 py-0`
                              }
                              onClick={() => setRotation(rotation + 10)}
                            >
                              <i className="bi bi-arrow-clockwise"></i>
                            </Button>
                          </Col>
                          <Col className="mt-2" md={3} sm={3} xs={3}>
                            <Button
                              className={
                                width.current > 500
                                  ? `theme-btn`
                                  : `theme-btn px-1 py-0`
                              }
                              onClick={() => setRotation(rotation - 10)}
                            >
                              <i className="bi bi-arrow-counterclockwise"></i>
                            </Button>
                          </Col>
                          <Col className="mt-2" md={3} sm={3} xs={3}>
                            <Button
                              className={
                                width.current > 500
                                  ? `theme-btn`
                                  : `theme-btn px-1 py-0`
                              }
                              onClick={() => setImageScale(imageScale + 0.1)}
                            >
                              <i className="bi bi-zoom-in"></i>
                            </Button>
                          </Col>
                          <Col className="mt-2" md={3} sm={3} xs={3}>
                            <Button
                              className={
                                width.current > 500
                                  ? `theme-btn`
                                  : `theme-btn px-1 py-0`
                              }
                              onClick={() => setImageScale(imageScale - 0.1)}
                            >
                              <i className="bi bi-zoom-out"></i>
                            </Button>
                          </Col>
                          <Col className="mt-2" md={3} sm={3} xs={3}>
                            <Button
                              className={
                                width.current > 500
                                  ? `theme-btn`
                                  : `theme-btn px-1 py-0`
                              }
                              onClick={() =>
                                setImageLeftRight(imageLeftRight - 1)
                              }
                            >
                              <i className="bi bi-arrow-left-circle"></i>
                            </Button>
                          </Col>
                          <Col className="mt-2" md={3} sm={3} xs={3}>
                            <Button
                              className={
                                width.current > 500
                                  ? `theme-btn`
                                  : `theme-btn px-1 py-0`
                              }
                              onClick={() =>
                                setImageLeftRight(imageLeftRight + 1)
                              }
                            >
                              <i className="bi bi-arrow-right-circle"></i>
                            </Button>
                          </Col>
                          <Col className="mt-2" md={3} sm={3} xs={3}>
                            <Button
                              className={
                                width.current > 500
                                  ? `theme-btn`
                                  : `theme-btn px-1 py-0`
                              }
                              onClick={() =>
                                setImageTopBottom(imageTopBottom - 1)
                              }
                            >
                              <i className="bi bi-arrow-up-circle"></i>
                            </Button>
                          </Col>
                          <Col className="mt-2" md={3} sm={3} xs={3}>
                            <Button
                              className={
                                width.current > 500
                                  ? `theme-btn`
                                  : `theme-btn px-1 py-0`
                              }
                              onClick={() =>
                                setImageTopBottom(imageTopBottom + 1)
                              }
                            >
                              <i className="bi bi-arrow-down-circle"></i>
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </>
              )}
              <Row>
                <Col md={6} className="mt-2">
                  <div className="numbers text-center">
                    <span
                      className="minus bg-danger"
                      onClick={() => orderQty > 1 && setOrderQty(orderQty - 1)}
                    >
                      -
                    </span>
                    <input
                      className="w-70"
                      type="number"
                      min={1}
                      value={orderQty}
                      onChange={(e) =>
                        e.target.value >= 1 && setOrderQty(+e.target.value)
                      }
                    />
                    <span
                      className="plus bg-success"
                      onClick={() => setOrderQty(orderQty + 1)}
                    >
                      +
                    </span>
                  </div>
                </Col>
                <Col md={6} className="mt-2">
                  {orderid ? (
                    <Button
                      className="w-100"
                      variant="success"
                      onClick={() => addToCart(getSingleProductData?.productType)}
                    >
                      Update <i className="bi bi-pen"></i>
                    </Button>
                  ) : (
                    !!(localStorage.getItem("pincode") || isLogin) && (
                      ((getSingleProductData?.productType === "customize") && !productType) ? null : <Button
                        className="w-100"
                        variant="success"
                        onClick={() =>
                          addToCart(getSingleProductData?.productType)
                        }
                      >
                        Add To Cart <i className="bi bi-cart-plus"></i>
                      </Button>
                    )
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <div className="productsreview mt-5">
              <ProductTab productDesc={getSingleProductData?.description} />
            </div>
          </Row>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ProductsDetails;

import React, { useEffect, useRef, useState } from "react";
import Footer from "./Footer";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import CommonHeader from "./CommonHeader";
import {getProducts, getProductRating} from "../../reducers/commonReducer";
import {getCategoriesFrontEnd} from "../../reducers/frontEndReducer";
import { Col, Row } from "react-bootstrap";
import dummy from "../../images/dummy.jpeg";
import commingsoon from '../../images/commingson.jpg'
import { userProductLikedList} from "../../reducers/orderDetailsReducer";
import { calculateImageSize, productRating } from "../../const";
import CustomizeProduct from "./CustomizeProduct";

const Shop = () => { 
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const productId = queryParameters.get("ref_");
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(100000);
  const width = useRef(window.innerWidth);
  useEffect(() => {
    const { cheight, cwidth } = calculateImageSize(width.current);
    dispatch(
      getProducts({
        categories: productId ? [productId] : "",
        width: cwidth,
        height: cheight
      })
    );
  }, [width.current]);

  useEffect(() => {
    dispatch(getCategoriesFrontEnd());
    // dispatch(getProductRating());
    localStorage.getItem("x-auth-token") && dispatch(userProductLikedList());
    // dispatch(
    //   getProducts({
    //     categories: productId ? [productId] : "",
    //     width: customizeHeight,
    //     height: customizeWidth
    //   })
    // );
  }, [width.current]);

  const { getProductsData } = useSelector((state) => state.commonReducer);
  const {getCatsFrontEndData} = useSelector((state) => state.frontEndReducer);
  const handleCheckboxChange = (subCat, event) => {
    let selectCat =
      subCat === false ? [...categories, event.target.id] : [...categories];
    // let selectSubCat =
    //   subCat === true
    //     ? [...subcategories, event.target.id]
    //     : [...subcategories];
    if (categories.includes(event.target.id)) {
      selectCat = selectCat.filter((cat) => cat !== event.target.id);
    }
    // if (subcategories.includes(event.target.id)) {
    //   selectSubCat = selectSubCat.filter((cat) => cat !== event.target.id);
    // }
    dispatch(
      getProducts({
        categories: selectCat,
        minprice: +minPrice,
        maxprice: +maxPrice,
      })
    );
    setCategories(selectCat);
  };

  return (
    <>
      <CommonHeader />
      <div className="container-fluid mt-3 mb-3">
        <div className="row">
          <div className="col-md-2">
            <div className="sidebar">
              <div className="row">
                <div className="col-md-12 col-7">
                  <div className="sidebar-categories area">
                    <Accordion defaultActiveKey={width > 468 ? "1" : "0"}>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          <span
                            href="#sidebar-categories"
                            className="sidebar-title"
                            data-bs-toggle="collapse"
                          >
                            Product Categories
                          </span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            {!!getCatsFrontEndData &&
                              getCatsFrontEndData.map((cat, index) => {
                                return (
                                  <li className="subcat" key={index}>
                                    <label>
                                      <input
                                        type="checkbox"
                                        name={cat?.name}
                                        id={cat?.id}
                                        value={cat?.id ? cat?.id : productId}
                                        onChange={(e) =>
                                          handleCheckboxChange(false, e)
                                        }
                                      />
                                      {cat?.name}
                                    </label>
                                  </li>
                                );
                              })}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="sidebar-filter area">
                    <Row className="hide-price-filter-text">
                      <Col md={7} xs={6}>
                        Filter by Price
                      </Col>
                      <Col md={5} xs={6}>
                        <span
                          onClick={() => {
                            setMinPrice(0);
                            setMaxPrice(100000);
                            dispatch(
                              getProducts({
                                categories:
                                  categories.length > 0 ? categories : "",
                                minprice: 0,
                                maxprice: 100000,
                              })
                            );
                          }}
                          className="btn clear-btn btn-block"
                        >
                          Clear
                        </span>
                      </Col>
                    </Row>
                    <section className="mt-2">
                      <div className="d-flex-block">
                        <Row className="rang-dsign">
                          <Col md={12} xs={6}>
                            <Form.Label>
                              Min Price <span>({minPrice})</span>
                            </Form.Label>
                            <Form.Range
                              max="100000"
                              step="1"
                              value={minPrice}
                              onChange={(e) => {
                                dispatch(
                                  getProducts({
                                    categories:
                                      categories.length > 0 ? categories : "",
                                    minprice: +e.target.value,
                                    maxprice: +maxPrice,
                                  })
                                );
                                setMinPrice(e.target.value);
                              }}
                            />
                          </Col>
                          <Col md={12} xs={6}>
                            <Form.Label>
                              Max Price <span>({maxPrice})</span>
                            </Form.Label>
                            <Form.Range
                              max="100000"
                              step="1"
                              value={maxPrice}
                              onChange={(e) => {
                                setMaxPrice(e.target.value);
                                dispatch(
                                  getProducts({
                                    categories:
                                      categories.length > 0 ? categories : "",
                                    minprice: +minPrice,
                                    maxprice: +e.target.value,
                                  })
                                );
                              }}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-1">
                          <Col md={5} xs={5}>
                            <select
                              name="minPrice"
                              value={minPrice}
                              onChange={(e) => {
                                dispatch(
                                  getProducts({
                                    categories:
                                      categories.length > 0 ? categories : "",
                                    minprice: +e.target.value,
                                    maxprice: maxPrice,
                                  })
                                );
                                setMinPrice(e.target.value);
                              }}
                            >
                              <option value="Min">Min</option>
                              <option value="250">₹250</option>
                              <option value="500">₹500</option>
                              <option value="1000">₹1000</option>
                              <option value="2000">₹2000</option>
                              <option value="5000">₹5000</option>
                              <option value="10000">₹10000</option>
                              <option value="20000">₹20000</option>
                              <option value="30000">₹30000</option>
                              <option value="40000">₹40000</option>
                              <option value="50000">₹50000</option>
                              <option value="60000">₹60000</option>
                            </select>
                          </Col>
                          <Col md={2} xs={2}>
                            To
                          </Col>
                          <Col md={5} xs={5}>
                            <select
                              value={maxPrice}
                              onChange={(e) => {
                                dispatch(
                                  getProducts({
                                    categories:
                                      categories.length > 0 ? categories : "",
                                    minprice: +e.target.value,
                                    maxprice: +maxPrice,
                                  })
                                );
                                setMaxPrice(e.target.value);
                              }}
                            >
                              <option value="">Max</option>
                              <option value="250">₹250</option>
                              <option value="500">₹500</option>
                              <option value="1000">₹1000</option>
                              <option value="2000">₹2000</option>
                              <option value="5000">₹5000</option>
                              <option value="10000">₹10000</option>
                              <option value="20000">₹20000</option>
                              <option value="30000">₹30000</option>
                              <option value="40000">₹40000</option>
                              <option value="50000">₹50000</option>
                              <option value="60000">₹60000</option>
                              <option value="100000">₹100000+</option>
                            </select>
                          </Col>
                        </Row>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-10">
            <div className="row">
              {!!getProductsData?.list && getProductsData?.list.length <= 0 ? (
                <div className={width > 468 ? "" : "notfountmbl"}>
                  <img src={commingsoon} />
                </div>
              ) : (
                !!getProductsData?.list &&
                getProductsData.list.map((data, index) => {
                  return (
                    <div className="col-md-4 col-sm-6 col-6" key={index}>
                      <div className="product-grid mx-0 card">
                        <div className="product-image">
                          <Link to={`/product/${data?.slug}`}>
                            <div className="card-img-actions ">
                            {/* <img
                                src={ data?.customizeDetails
                                  ? data?.customizeDetails[0]?.productImg : dummy}
                                className="img-fluid"
                                alt={data?.name}
                              /> */}
                              {/* <CustomizeProduct customizeDetails={data?.customizeDetails}/> */}
                            {(data?.productType === "customize") ?  <CustomizeProduct customizeDetails={data?.customizeDetails}/>  : <img
                              src={
                                data?.images
                                ? `/${data?.images[data?.defaultImage]}`
                                  : dummy
                              }
                              className="img-fluid"
                              alt={data?.name}
                            />
                              }
                            </div>
                          </Link>
                          {!!data?.sale && (
                            <span className="product-discount-label">
                              -
                              {(
                                (100 * (data?.mrp - data?.sale)) /
                                data?.mrp
                              ).toFixed(2)}
                              %
                            </span>
                          )}
                          <ul className="product-links">
                            <li>
                              <NavLink to={`/product/${data?.slug}`}>
                                <i className="bi bi-search"></i>
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                        <div className="product-content position-relative">
                          <button
                            onClick={() => navigate(`/product/${data?.slug}`)}
                            className="add-to-cart"
                          >
                           {data?.productType === "customize" ? `Personalize & Customize` : `Add to Cart`}
                          </button>

                          <Link to={`/product/${data?.slug}`}>
                            <div className="card-body text-center">
                              <div className="product-name-box">
                                <p
                                  className="product-fullname mb-0"
                                  dangerouslySetInnerHTML={{
                                    __html: data?.name.substring(0, 45) + "...",
                                  }}
                                ></p>
                              </div>
                              <hr />
                             <p className="mb-1">{productRating(5)}</p>
                              <h3 className="mb-0 font-weight-semibold product__price text-success">
                                <span className="discount-price text-danger">Rs.{data?.mrp}</span> Rs.{data?.sale} 
                              </h3>
                              {/* <div className="rating">
                                {!!productRatingData &&
                                  productRatingData[data.id] &&
                                  productRating(productRatingData[data.id])}
                              </div> */}
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Shop;

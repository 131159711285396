import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { FieldArray, Formik } from "formik";
import * as yup from "yup";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDispatch, useSelector } from "react-redux";
import {
  getAttributes,
  getTaxgsts,
  CreateProduct,
  userDropDownWithRole,
  updateProduct,
  getPackage,
  getActiveTaxClass,
  getActivePackage,
  getSingleProduct,
  getFileSettings,
} from "../../reducers/commonReducer";
import {
  getCategoriesFrontEnd,
} from "../../reducers/frontEndReducer";
import { useNavigate } from "react-router-dom";
import { productList } from "../../const";
import SideMenu from "../../Components/SideMenu";

const schema = yup.object().shape({
  name: yup.string().required(),
  sku: yup.string().required(),
  weight: yup.string().required(),
  description: yup.string(),
  mrp: yup.string().required(),
  vendor:yup.string().required(),
  sale: yup.string().required(),
  backordering: yup.string().required(),
  categories: yup.string().required(),
  settings: yup
    .array()
    .min(1, 'Please select at least one shipment')
    .required('This field is required'),
  tax_class: yup.string().required(),
  package: yup.string().required(),
  attributes: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      value: yup.string(),
    })
  ),
  metaTitle: yup.string(),
  metaDescription: yup.string(),
  metaTags: yup.string(),
  metaSchema: yup.string(),
  productType: yup.string().required("Product type is required"),
  hotSelling: yup.string(),
  status: yup.bool().required(),
  showOnHome: yup.bool().required(),
}); 

function ProductFieldAction({bgImg, bgBannerColor, images, inputs}) {
  const queryParameters = new URLSearchParams(window.location.search);
  const slug = queryParameters.get("slug");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getActiveTaxClass());
    dispatch(userDropDownWithRole({
        role: "669755f3aa1fe8156c56aabf"
    }))
    dispatch(getFileSettings())
    dispatch(getCategoriesFrontEnd());
    dispatch(getAttributes());
    dispatch(getActivePackage())
    if(slug){
      dispatch(getSingleProduct({
        slug
      }))
    }
  }, []);
  const {
    getAttributesData,
    activeTaxClassList,
    userDropDownWithRoleList,
    getSingleProductData,
    activePackageList,
    getFileSettingsList
  } = useSelector((state) => state.commonReducer);
  const {
    getCatsFrontEndData,
  } = useSelector((state) => state.frontEndReducer);


  const [initialValues, setInitialValues] = useState({
    name: "",
    sku: "",
    weight: "",
    description: "",
    mrp: "",
    sale: "",
    backordering: true,
    categories: "",
    settings:"",
    tax_class: "",
    package:'',
    vendor: "",
    attributes: [
      {
        attributeid: "",
        value: "",
      },
    ],
    productType: "",
    metaTitle: "",
    metaDescription: "",
    metaTags: "",
    metaSchema: "",
    hotSelling: false,
    status: true,
    productImg: bgImg,
    productbgColor: bgBannerColor,
    customerImg: [],
    inputFields: [],
    showOnHome: "",
  });
  useEffect(() => {
    const fetchData = async () => {
      // dispatch(getTaxgsts());
      // dispatch(userDropDownWithRole({ role: "669755f3aa1fe8156c56aabf" }));
      // dispatch(getCategoriesFrontEnd());
      // dispatch(getAttributes());

      if (getSingleProductData) {
        setInitialValues({
          id: getSingleProductData.id || "",
          name: getSingleProductData.name || "",
          sku: getSingleProductData.sku || "",
          weight: getSingleProductData.weight || "",
          description: getSingleProductData.description || "",
          mrp: getSingleProductData.mrp || "",
          sale: getSingleProductData.sale || "",
          backordering: true,
          categories: getSingleProductData.categories?.id || "",
          settings: getSingleProductData.settings?.map((id)=>id) || "",
          tax_class: getSingleProductData.tax_class?.id || "",
          package: getSingleProductData.package?.id || "",
          vendor: getSingleProductData.vendor?.id || "",
          attributes: getSingleProductData.attributes?.map((attribute) => ({
            attributeid: attribute?.attributeid?.id,
            value: attribute?.value,
          })) || [
            {
              attributeid: "",
              value: "",
            },
          ],
          productType: getSingleProductData.productType || "",
          metaTitle: getSingleProductData.metaTitle || "",
          metaDescription: getSingleProductData.metaDescription || "",
          metaTags: getSingleProductData.metaTags || "",
          metaSchema: getSingleProductData.metaSchema || "",
          hotSelling: getSingleProductData.hotSelling || false,
          status: getSingleProductData.status || true,
          productImg: getSingleProductData.productImg || bgImg,
          productbgColor: getSingleProductData.productbgColor || bgBannerColor,
          customerImg: getSingleProductData.customerImg || [],
          inputFields: getSingleProductData.inputFields || [],
          showOnHome: getSingleProductData.showOnHome || false,
        });
      }
    };

    fetchData();
  }, [dispatch, getSingleProductData, bgImg, bgBannerColor]);
  // useEffect(() => {
  //   setInitialValues((prevValues) => ({
  //     ...prevValues,
  //     productImg: bgImg,
  //     productbgColor: bgBannerColor,
  //     customerImg: [...images],
  //     inputFields: [...inputs],
  //   }));
  // }, [images, bgImg, bgBannerColor, inputs]);
  console.log("getFileSettingsList", getFileSettingsList)
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid">
        <Row>
          <Formik
            validationSchema={schema}
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={async(values, { resetForm }) => {
              // console.log("values", values)
            const apiResp = await dispatch(slug ? updateProduct(values) : CreateProduct(values));
            if(apiResp?.payload?.status === 1){
              setTimeout(() => {
                navigate( values?.productType === "normal" ? productList : `/customize-product-img?id=${apiResp?.payload?.product?.id}`) 
                },1000)
            }
            }}
          >
            
            {({ handleSubmit, handleChange, values, setFieldValue, errors }) => (
              <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col md={3} sm={12}>
                    <Form.Group>
                      <Form.Label>Product Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="name"
                        className="form-control bg"
                        value={values.name}
                        onChange={handleChange}
                        isInvalid={!!errors.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={2} sm={12}>
                    <Form.Group>
                      <Form.Label>SKU</Form.Label>
                      <Form.Control
                        type="text"
                        name="sku"
                        className="form-control bg"
                        value={values.sku}
                        onChange={handleChange}
                        isInvalid={!!errors.sku}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.sku}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={2} sm={12}>
                    <Form.Group>
                      <Form.Label>Weight</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="In Grams"
                        name="weight"
                        className="form-control bg"
                        value={values.weight}
                        onChange={handleChange}
                        isInvalid={!!errors.weight}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.weight}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4} sm={12}>
                        <Form.Label> Shipment </Form.Label>
                        <Form.Control
                          as="select"
                          multiple={true}
                          onChange={handleChange}
                          name="settings"
                          className="form-control bg"
                          value={values.settings}
                          isInvalid={!!errors.settings}
                        >
                          <option>Select Shipment</option>
                          {!!getFileSettingsList &&
                            getFileSettingsList?.map((data, index) => {
                              return (
                                <option value={data?.id} key={index}>
                                  {data?.name}
                                </option>
                              );
                            })}
                        </Form.Control>
                      </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12} sm={12}>
                    <CKEditor
                      editor={ClassicEditor}
                      data={values?.description ? values?.description : "Enter Your Product Description......"}
                      onReady={(editor) => {
                        console.log("Editor is ready to use!", editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue("description", data);
                        console.log({ event, editor, data });
                      }}
                      onBlur={(event, editor) => {
                        console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        console.log("Focus.", editor);
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12} sm={12}>
                    <Row>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>MRP</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="₹ 100"
                            name="mrp"
                            className="form-control bg"
                            value={values.mrp}
                            onChange={handleChange}
                            isInvalid={!!errors.mrp}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.mrp}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Sale Price</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Sale Price"
                            name="sale"
                            className="form-control bg"
                            value={values.sale}
                            onChange={handleChange}
                            isInvalid={!!errors.sale}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.sale}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="mt-4" md={12} sm={12}>
                    <Row>
                      <Col md={6} sm={12}>
                        <FieldArray name="attributes">
                          {({ remove, push }) => (
                            <>
                              {!!values.attributes &&
                                values.attributes.map((data, index) => {
                                  return (
                                    <Row key={index}>
                                      <Col md={4} sm={12}>
                                        <Form.Label>Product Attribute</Form.Label>
                                        <Form.Control
                                          as="select"
                                          onChange={handleChange}
                                          className="form-control bg"
                                          name={`attributes[${index}].attributeid`}
                                          value={values.attributes[index].attributeid || ''}
                                          isInvalid={!!errors.attributes}
                                        >
                                          <option value="">Select Attribute</option>
                                          {!!getAttributesData?.list &&
                                            getAttributesData.list.map(
                                              (data, index) => {
                                                return (
                                                  <option
                                                    value={data?.id}
                                                    key={index}
                                                  >
                                                    {data?.name}
                                                  </option>
                                                );
                                              }
                                            )}
                                        </Form.Control>
                                      </Col>
                                      <Col md={4} sm={12}>
                                        <Form.Group>
                                          <Form.Label>Attribute Value</Form.Label>
                                          <Form.Control
                                            type="text"
                                            onChange={handleChange}
                                            name={`attributes.${index}.value`}
                                            value={values.attributes[index].value}
                                            className="form-control bg"
                                            isInvalid={!!errors.attributes}
                                          />
                                        </Form.Group>
                                      </Col>
                                      <Col md={2} sm={12} className="mt-4">
                                        {index !== 0 && (
                                          <Button
                                            variant="danger"
                                            onClick={() => remove(index)}
                                          >
                                            <i className="bi bi-trash3-fill"></i>
                                          </Button>
                                        )}
                                      </Col>
                                      <Col md={2} sm={12} className="mt-4">
                                        <Button
                                          variant="success"
                                          onClick={() =>
                                            push({ attributeid: "", value: "" })
                                          }
                                        >
                                          <i className="bi bi-plus-lg"></i>
                                        </Button>
                                      </Col>
                                    </Row>
                                  );
                                })}
                            </>
                          )}
                        </FieldArray>
                        {/* // you have to  */}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <hr />

                <Row className="mt-3">
                  <Col md={12} sm={12}>
                    <Row>
                    <Col md={4} sm={12}>
                        <Form.Label> Category </Form.Label>
                        <Form.Control
                          as="select"
                          onChange={handleChange}
                          name="categories"
                          className="form-control bg"
                          value={values.categories}
                          isInvalid={!!errors.categories}
                        >
                          <option>Select Category</option>
                          {!!getCatsFrontEndData &&
                            getCatsFrontEndData?.map((data, index) => {
                              return (
                                <option value={data?.id} key={index}>
                                  {data?.name}
                                </option>
                              );
                            })}
                        </Form.Control>
                      </Col>
                      <Col md={2} sm={12}>
                        <Form.Label> Tax - Class </Form.Label>
                        <Form.Control
                          as="select"
                          onChange={handleChange}
                          name="tax_class"
                          className="form-control bg"
                          value={values.tax_class}
                          isInvalid={!!errors.tax_class}
                        >
                          <option>Select TaxClass</option>
                          {!!activeTaxClassList?.list &&
                            activeTaxClassList.list.map((data, index) => {
                              return (
                                <option value={data?.id} key={index}>
                                  {data?.name}
                                </option>
                              );
                            })}
                        </Form.Control>
                      </Col>
                      <Col md={2} sm={12}>
                        <Form.Label> Package (Box) </Form.Label>
                        <Form.Control
                          as="select"
                          onChange={handleChange}
                          name="package"
                          className="form-control bg"
                          value={values.package}
                          isInvalid={!!errors.package}
                        >
                          <option>Select Package</option>
                          {!!activePackageList &&
                            activePackageList?.map((data, index) => {
                              return (
                                <option value={data?.id} key={index}>
                                  {data?.name}
                                </option>
                              );
                            })}
                        </Form.Control>
                      </Col>
                      <Col md={2} sm={12}>
                        <Form.Label> Vendor </Form.Label>
                        <Form.Control
                          as="select"
                          onChange={handleChange}
                          name="vendor"
                          className="form-control bg"
                          value={values.vendor}
                          isInvalid={!!errors.vendor}
                        >
                            <option>Select Vendor</option>
                          {!!userDropDownWithRoleList &&
                            userDropDownWithRoleList?.map((data, index) => {
                              return (
                                <option value={data?.id} key={index}>
                                  {`${data?.firstName} ${data?.lastName}`}
                                </option>
                              );
                            })}
                        </Form.Control>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} sm={12}>
                    <Row>
                      <Col md={12} sm={12}></Col>
                    </Row>
                  </Col>
                </Row>
                <hr className="mt-3" />
                <Row className="mt-3">
                  <Col md={2} sm={12}>
                    <Form.Label>Hot Selling</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={handleChange}
                      name="hotSelling"
                      className="form-control bg"
                      value={values.hotSelling}
                      isInvalid={!!errors.hotSelling}
                    >
                      <option value="">Select Hot Selling</option>
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </Form.Control>
                  </Col>
                </Row>
                <hr className="mt-3" />
                <Row className="mt-3">
                  <Col md={2} sm={12} className=" ">
                    <Form.Group>
                      <Form.Label>Meta Title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="metaTitle"
                        className="form-control bg"
                        value={values.metaTitle}
                        onChange={handleChange}
                        isInvalid={!!errors.metaTitle}
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.metaTitle}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={2} sm={12} className=" ">
                    <Form.Group>
                      <Form.Label>Meta Tags</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="metaTags"
                        className="form-control bg"
                        value={values.metaTags}
                        onChange={handleChange}
                        isInvalid={!!errors.metaTags}
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.metaTags}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Meta Schema</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="metaSchema"
                        value={values.metaSchema}
                        onChange={handleChange}
                        rows={2}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Meta Discription</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="metaDescription"
                        value={values.metaDescription}
                        onChange={handleChange}
                        rows={2}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-3 mb-3">
                  <Col md={3} sm={12}>
                    <Form.Label>Product Status </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        setFieldValue("status", e.target.value);
                      }}
                      name="status"
                      className="form-control bg"
                      value={values.status}
                      isInvalid={!!errors.status}
                    >
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </Form.Control>
                  </Col>
                  <Col md={3} sm={12}>
                    <Form.Label>Show On Home </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        setFieldValue("showOnHome", e.target.value);
                      }}
                      name="showOnHome "
                      className="form-control bg"
                      value={values.showOnHome }
                      isInvalid={!!errors.showOnHome }
                    >
                      <option value="">Select ShowOnHome</option>
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </Form.Control>
                  </Col>
                  <Col md={3} sm={12}>
                    <Form.Label> Product Type </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        setFieldValue("productType", e.target.value);
                      }}
                      name="productType"
                      className="form-control bg"
                      value={values.productType}
                      isInvalid={!!errors.productType}
                    >
                      <option value="">Product Type</option>
                      <option value={"normal"}>Normal</option>
                      <option value={"customize"}>Customize</option>
                      <option value={"limitedEdition"}>Limited Edition</option>
                    </Form.Control>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={12} sm={12} className="text-left ">
                    <Button variant="success" className="w-100 mt-3" type="submit">
                      {slug ? "Update" : "Add Product"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Row>
      </div>
    </>
  );
}

export default ProductFieldAction;

import React, { useEffect, useRef, useState } from "react";
import Footer from "../Sub/Footer";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Sub/Header";
import { Button, Col, Row } from "react-bootstrap";
import { getCartlist, updateCart } from "../../reducers/commonReducer";
import Spinner from "react-bootstrap/Spinner";
import "../../table.css";
import { getUserAddress, openNewTab, shop } from "../../const";

const Checkout = () => {
  const dispatch = useDispatch();
  const width = useRef(window.innerWidth);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  //api's
  useEffect(() => {
    setLoading(true);
    dispatch(getCartlist());
    setLoading(false);
  }, []);

  const { getCartlistData } = useSelector((state) => state.commonReducer);
  const {} = useSelector((state) => state.orderDetailReducer);
  //update quantity
  const updateQuantity = async (quantity, id, itemRemove) => {
    if (itemRemove || quantity >= 1) {
      const apiResp = await dispatch(
        updateCart({ quantity, id, delete: itemRemove })
      );
      if (apiResp.payload.status) {
        dispatch(getCartlist());
      }
    }
  };
  useEffect(() => {
    if(getCartlistData?.list?.length === 0){
      navigate(shop)
    }
  }, [getCartlistData?.list])
  let totalAmount = 0;
  return (
    <>
      <Header />
      <div className="container pb-5 mt-4">
        <Row>
          <Col md={12}>
            <h4 className="d-flex justify-content-between align-items-center mb-3">
              <b>
                <span className="your-cart">Your cart</span>
              </b>
            </h4>
          </Col>
        </Row>
        {loading ? (
          <Row className="justify-content-center">
            <Spinner animation="border" />
          </Row>
        ) : (
          <>
            <Row className="no-more-tables">
              <Col md={12}>
                {getCartlistData?.list?.length && (
                  <table striped className="overflow-hidden">
                    <thead>
                      <tr>
                        <th>Sr No.</th>
                        <th>Product Name</th>
                        <th>Image</th>
                        <th>Qty</th>
                        <th></th>
                        <th>Price</th>
                        <th>SubTotal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getCartlistData?.list?.map((item, index) => {
                        const subTotal = item?.quantity * item?.price;
                        totalAmount += subTotal;
                        let productImg = item?.product?.productType === "customize" ? item?.mergeimg : item?.product?.images?.[0]
                        return (
                          <tr key={index}>
                            <td data-title="Sr No.">{index + 1}</td>
                            <td data-title="Product Name">
                              {item?.product?.name}
                            </td>
                            <td data-title="Image">
                                <img
                                  className={
                                    width.current > 500
                                      ? "w-15-percent"
                                      : "w-50"
                                  }
                                  src={productImg}
                                  alt={item?.product?.name}
                                  loading="lazy"
                                  onClick={() => openNewTab(productImg)}
                                />
                            </td>
                            <td data-title="Qty">
                              <div class="numbers text-center d-flex">
                                <span
                                  class="minus bg-danger"
                                  onClick={async () =>
                                    updateQuantity(item?.quantity - 1, item?.id)
                                  }
                                >
                                  -
                                </span>
                                <input
                                  className="w-70"
                                  type="number"
                                  min={1}
                                  disabled
                                  value={item?.quantity}
                                />
                                <span
                                  class="plus bg-success"
                                  onClick={async () =>
                                    updateQuantity(item?.quantity + 1, item?.id)
                                  }
                                >
                                  +
                                </span>
                              </div>
                            </td>
                            <td data-title="Delete">
                              <Button
                                variant="danger"
                                className="px-2 py-1"
                                onClick={async () =>
                                  updateQuantity(0, item?.id, true)
                                }
                              >
                                <i class="bi bi-trash3"></i>
                              </Button>
                            </td>
                            <td data-title="Price" className="text-success">
                              <span class="discount-price text-danger">
                                Rs.{item?.product?.mrp}
                              </span>{" "}
                              Rs.{item?.price}
                            </td>
                            <td data-title="SubTotal">
                              <b>{item?.quantity * item?.price}/-</b>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    {width.current > 500 ? (
                      <tfoot>
                        <tr>
                          <th colSpan={6}>Total</th>
                          <th>{totalAmount}/-</th>
                        </tr>
                      </tfoot>
                    ) : (
                      <Row>
                        <Col xs={9} sm={9}>
                          <h5 className="text-danger"><b>Total</b></h5>
                        </Col>
                        <Col xs={3} sm={3}>
                          <h5 className="text-danger"><b>{totalAmount}/-</b></h5>
                        </Col>
                      </Row>
                    )}
                  </table>
                )}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12}>
                <Link to={`${getUserAddress}?amount=${totalAmount}`}>
                  <Button className="theme-btn w-100">
                    Buy Now <i className="bi bi-arrow-right"></i>
                  </Button>
                </Link>
              </Col>
            </Row>
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Checkout;

import React from "react";
import { NavLink } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import dummy from "../../images/dummy.jpeg";
import { productRating } from "../../const";
import CustomizeProduct from "./CustomizeProduct";

const SingleProduct = ({ title, sale, mrp, slug, productType, customizeDetails, defaultImage, images }) => {
  const navigate = useNavigate();
  return (
    <>
      <section className="best__sell pt-15 pb-40 mt-3">
        <div className="row">
          <div className="col-xl-12">
            <div className="tab-content" id="best-sell-tabContent">
              <div
                className="tab-pane fade show active"
                id="new"
                role="tabpanel"
                aria-labelledby="new-tab"
              >
                {/* bg-design-border */}
                <div className="product-grid card m-0">
                  <div className="product-image ">
                    <div className="card-img-actions ">
                      <Link
                        to={`/product/${slug}`}
                        className="text-default mb-2"
                      >
                        {(productType === "customize") ? <CustomizeProduct coloums={4} customizeDetails={customizeDetails}/> : <img
                          src={ images
                            ? images[defaultImage] : dummy}
                          className="img-fluid"
                          alt={title}
                        />
                        }
                      </Link>
                    </div>
                    {!!sale && (
                      <span className="product-discount-label">
                        -{((100 * (mrp - sale)) / mrp).toFixed(2)}%
                      </span>
                    )}
                    <ul className="product-links">
                      <li onClick={(e) => navigate(`/product/${slug}`)}>
                        <NavLink to="">
                          <i className="bi bi-search"></i>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                  <div className="product-content">
                    <button
                      className="add-to-cart"
                      onClick={() => navigate(`/product/${slug}`)}
                    >
                      {productType === "customize" ? `Personalize & Customize` : `Add to Cart`}
                    </button>
                    <Link
                      to={`/product/${slug}`}
                      className="text-default mb-2"
                      data-abc="true"
                    >
                      <div className="card-body text-center">
                        <div className="mb-mbl-0 product-name-box">
                          <b className="product-name">{title}</b>
                        </div>
                        <hr />
                        <p className="mb-1">{productRating(5)}</p>
                        <h3 className="mb-0 font-weight-semibold product__price text-success">
                          <span className="discount-price text-danger">
                            Rs.{mrp}
                          </span>{" "}
                          Rs.{sale}
                        </h3>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SingleProduct;

export const home = "/";
export const thankyou = "/thankyou";
export const checkout = "/checkout";
export const about = "/about";
export const shop = "/shop";
export const login = "/login";
export const signup = "/signup";
export const addUserAddress = "/add-user-address";
export const getUserAddress = "/user-address";
export const userDashboard = "/user-dashboard";
export const contact = "/contact";
export const dashboard = "/dashboard";
export const attributes = "/attributes";
export const roles = "/roles";
export const users = "/users";
export const vendors = "/vendors";
export const checker = "/checker";
export const manager = "/manager";
export const userCartList = "/user-cart";
export const categories = "/categories";
export const banners = "/banners";
export const vendor = "/vendor";
export const coupons = "/coupons";
export const settings = "/settings";
export const orders = "/orders";
export const product = "/product";
export const productLabels = "/product-labels";
export const warehouse = "/warehouse";
export const fileSettings = "/file-setting";
export const orderPdf = "/order-pdf";
export const permissions = "/permissions";
export const warranty = "/warranty";
export const reviews = "/reviews";
export const addProduct = "/add-product";
export const productList = "/product-list";
export const packageList = "/package";
export const socialMediaSetting = "/social-media-setting";
export const EmailTemplates = "/email-templates";
export const infoPages = "/pages";
export const taxClasses = "/tax-classes";
export const forgotPass = "/forgot-password";
export const phoneVerify = "/phone-verify";
export const verifyOtp = "/verify-otp";
export const newPassword = "/create-new-password";
export const connection = "/connect";
export const shippingCharges = "/shipping-charges";
export const trackers = "/trackers";
export const rewardSlab = "/reward-slab";
export const offers = "/offers";
export const currentUrl = window.location.href;

// common Functions
export const productRating = (times) => {
  var repeatedString = "";
  while (times > 0) {
    repeatedString += "⭐";
    times--;
  }
  return (
    <ul
      dangerouslySetInnerHTML={{ __html: !!repeatedString && repeatedString }}
    />
  );
};
export const dynamicPriceRange = (price) => {
  const keys = Object.keys(price);
  const lastKey = keys[keys.length - 1];
  const lastValue = price[lastKey];
  return lastValue
}

export const openNewTab = (url) => {
  window.open(url, '_blank');
};

export const handleDownload = (fileUrl) => {
  const anchor = document.createElement('a');
  anchor.href = fileUrl;
  anchor.download = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

export const calculateImageSize = (width, coloums) => {
  let cheight = "0px";
  let widthSize = "0px";
    if (width < 425) {
      cheight = "142px";
      widthSize = "142px";
    }else if (width >= 425 && width < 480) {
      cheight = "192px";
      widthSize = "192px";
    }else if (width >= 480 && width < 768) {
      cheight = "215px";
      widthSize = "215px";
    } else if (width >= 768 && width < 1024) {
      cheight = "300px";
      widthSize = "300px";
    } else if (width >= 1024) {
      if(coloums === 4){
        cheight = "305px";
      widthSize = "305px";
      }else {
        cheight = "370px";
      widthSize = "370px";
      }
    }
  return { cheight, cwidth: widthSize };
};
export const fontFamilies = [
  'Roboto',
  'Sevillana',
  'Aleo Regular',
  'Elmessiri bold',
  'Gilroy Extrabold',
  'Gilroy Light',
  'GrandHotel Regular',
  'Indigo Outline',
  'Indigo Regular',
  'KozGoPr6N Bold',
  'KozGoPr6N Regular',
  'KozGoPro Regular',
  'Lato Regular',
  'Locomotype GigaSansRegular',
  'MyriadArabic Regular',
  'MyriadHebrew Regular',
  'MyriadPro Regular',
  'NotoSansGurmukhi ExtraCondensed Regular',
  'NunitoSans Regular',
  'Oswald Regular',
  'Pluto Regular',
  'Roboto Slab Regular',
  'Trebuc',
  'Trebucbd',
  'Trebucbi',
  'Trebucit'
]

import React, { useState } from "react";
import { Button, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import AddProductImages from "./AddProductImages";

function ProductImageUpdate(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button onClick={handleShow} className="theme-btn cursor-pointer"><i class="bi bi-eye-fill"></i></Button>
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{"Update Product Images"}</Modal.Title>
        </Modal.Header>
        <Row className="p-4">
          <AddProductImages
            pImages={props?.images}
            pID={props?.productId}
            dfltImg={props?.dimg}
          />
        </Row>
      </Modal>
    </>
  );
}

export default ProductImageUpdate;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  adminOrders,
  adminOrdersExport,
  resetAdminOrderList,
} from "../../reducers/orderDetailsReducer";
import SideMenu from "../../Components/SideMenu";
import moment from "moment";
import OrderUpdate from "./OrderUpdate";
import OrderDetails from "./OrderDetails";
import { Button, Row, Form, InputGroup, Col } from "react-bootstrap";
import { getTrackers } from "../../reducers/commonReducer";
import AdminOrderDetail from "./AdminOrderDetail";

const OrdersList = () => {
  const [search, setSearch] = useState();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [orderStatus, setOrderStatus] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedOrders, setSelectedOrders] = useState([]);
  const queryParameters = new URLSearchParams(window.location.search);
  const orderId = queryParameters.get("orderid");
  const { userId } = useParams();
  useEffect(() => {
    dispatch(resetAdminOrderList());
    let timeOut = setTimeout(() => {
      dispatch(
        adminOrders({
          search: search,
          userId,
        })
      );
    }, 800);
    return () => clearTimeout(timeOut);
  }, [search]);
  useEffect(() => {
    dispatch(getTrackers());
  }, []);

  const { adminOrdersData, adminOrdersBlank } = useSelector((state) => state.orderDetailReducer);
  let orderQty = 0;
  // combo selection
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allOrderIds = adminOrdersData.map((order) => order.id);
      setSelectedOrders(allOrderIds);
    } else {
      setSelectedOrders([]);
    }
  };
  
  const handleSelectOrder = (event, orderId) => {
    if (event.target.checked) {
      setSelectedOrders([...selectedOrders, orderId]);
    } else {
      setSelectedOrders(selectedOrders.filter((id) => id !== orderId));
    }
  };
  return (
    <>
      <SideMenu />
      {orderId ? (
        <div className="mt-extra content container-fluid main-print-conatiner">
         {
          localStorage.getItem("slug") === "admin" ? <AdminOrderDetail/> : <OrderDetails />
         }
        </div>
      ) : (
        <div className="mt-extra content container-fluid">
          <Row className="mb-1">
            <Col md={4} className="mt-4">
              <InputGroup>
                <InputGroup.Text id="inputGroup-sizing-default">
                  <i className="bi bi-search"></i>
                </InputGroup.Text>
                <Form.Control
                  placeholder="Search anything..."
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col md={2}>
              <Form.Group>
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Start Date"
                  name="startDate"
                  onChange={(e) => {
                    dispatch(resetAdminOrderList());
                    dispatch(
                      adminOrders({
                        userId,
                        endDate,
                        startDate: e.target.value,
                        search,
                        page,
                      })
                    );
                    dispatch(
                      adminOrdersExport({
                        userId,
                        endDate,
                        startDate: e.target.value,
                      })
                    );
                    setStartDate(e.target.value);
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group>
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="End Date"
                  name="endtDate"
                  onChange={(e) => {
                    dispatch(resetAdminOrderList());
                    dispatch(
                      adminOrders({
                        search: search,
                        userId,
                        endDate: e.target.value,
                        startDate,
                        search,
                        page,
                      })
                    );
                    dispatch(
                      adminOrdersExport({
                        userId,
                        endDate: e.target.value,
                        startDate,
                      })
                    );
                    setEndDate(e.target.value);
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={2} className="mt-4">
              <Form.Select
                onChange={(e) => {
                  dispatch(resetAdminOrderList());
                  setOrderStatus(+e.target.value);
                  dispatch(
                    adminOrders({
                      status:
                        e.target.value === "all" ? "all" : +e.target.value,
                      page,
                      startDate,
                      endDate,
                    })
                  );
                  dispatch(
                    adminOrdersExport({
                      status:
                        e.target.value === "all" ? "all" : +e.target.value,
                      endDate,
                      startDate,
                    })
                  );
                }}
                aria-label="Default select example"
              >
                <option value={"all"}>Select Status</option>
                <option value={"all"} className="text-dark">
                  <b>All</b>
                </option>
                <option value={0} className="text-primary">
                  <b>Pending</b>
                </option>
                <option value={2} className="text-warning">
                  <b>Processing</b>
                </option>
                <option value={3} className="text-info">
                  <b>Shipping</b>
                </option>
                <option value={4} className="text-success">
                  <b>Completed</b>
                </option>
                <option value={1} className="text-danger">
                  <b>Cancel</b>
                </option>
              </Form.Select>
            </Col>
            <Col md={1} className="mt-4">
              <Button variant="danger" onClick={() => window.location.reload()}>
                Clear
              </Button>
            </Col>
            <Col md={1} className="mt-4">
            </Col>
          </Row>
          <table className="table w-100">
            <thead>
              <tr>
                <th>
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={adminOrdersData.length > 0 && selectedOrders.length === adminOrdersData.length}
                />
                </th>
                <th>Order</th>
                <th>Items</th>
                <th>Date</th>
                <th>Total</th>
                <th>Payment Status</th>
                <th>Customer</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!!adminOrdersData &&
                adminOrdersData.map((orders, index) => {
                  let orderItemQty = 0;
                  orders?.items?.forEach((item) => {
                    orderItemQty += item.quantity;
                  });
                  orderQty += orderItemQty;
                  return (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => handleSelectOrder(e, orders.id)}
                          checked={selectedOrders.includes(orders.id)}
                        />
                      </td>
                      <td>
                        <Link to={`/orders?orderid=${orders?.id}`}>
                          {orders?.orderid}
                        </Link>
                      </td>
                      <td>{orderQty}</td>
                      <td>
                        {moment(orders?.updatedAt).format("D MMM [at] h:mm a")}
                      </td>
                      <td>{orders?.payment}/-</td>
                      <td>???</td>
                      <td>
                        {orders?.shippingAddress?.firstName
                          ? `${orders?.shippingAddress?.firstName} ${orders?.shippingAddress?.lastName}`
                          : "No Customer"}
                      </td>
                      <td
                        className={
                          (orders?.status === 0 && "text-primary") ||
                          (orders?.status === 1 && "text-danger") ||
                          (orders?.status === 2 && "text-warning") ||
                          (orders?.status === 3 && "text-info") ||
                          (orders?.status === 4 && "text-success")
                        }
                      >
                        <b>
                          {(orders?.status === 0 && "Pending") ||
                            (orders?.status === 1 && "Cancel") ||
                            (orders?.status === 2 && "Processing") ||
                            (orders?.status === 3 && "Shipping") ||
                            (orders?.status === 4 && "Completed")}
                        </b>
                      </td>
                      <td>
                        <Link to={`/orders?orderid=${orders?.id}`}>
                          <Button className="m-1 bg-thememain btn-mbl-text">
                            <i className="bi bi-eye-fill"></i>
                          </Button>
                        </Link>
                        <OrderUpdate
                          title={"Update Order Status"}
                          orderStatus={orders?.status}
                          orderId={orders?.id}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {!!adminOrdersBlank && adminOrdersBlank.length > 0 ? (
            <Row>
              <Button
                onClick={() => {
                  setPage(page + 1);
                  dispatch(
                    adminOrders({
                      search: search,
                      page: page + 1,
                      status: orderStatus,
                      startDate,
                      endDate,
                    })
                  );
                }}
              >
                Load More
              </Button>
            </Row>
          ) : (
            <p className="text-center">
              <b>No record found 😔.</b>
            </p>
          )}
        </div>
      )}
    </>
  );
};

export default OrdersList;

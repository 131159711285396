import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import sumBy from "lodash/sumBy";
import Modal from "react-bootstrap/Modal";
import { applyCartOffer, getOffers } from "../../../reducers/commonReducer";
import Coupon from "./Coupon";

const Offers = ({ couponAmountStatus, couponTypeStatus }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const [couponQty, setCouponQty] = useState();
  useEffect(() => {
    dispatch(getOffers());
  }, []);
  const { offersData, getCartlistData } = useSelector(
    (state) => state.commonReducer
  );
  //cart item count
  let cartItems = sumBy(
    !!getCartlistData?.list && getCartlistData?.list,
    function (o) {
      return o.quantity;
    }
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button className="theme-btn text-white text-align-start border font-weight-bold rounded w-100" onClick={handleShow}>
      🎁🎉 Add Coupon <i className="bi bi-arrow-right float-right"></i>
      </Button>
      <Modal show={show} onHide={handleClose} className="modal-bottom" >
        <Modal.Body className="py-5">
          <Row>
            <Col md={12} className="mb-2">
              <Coupon couponAmount={couponAmountStatus} popupClose={handleClose}/>
            </Col>
          </Row>
          {offersData &&
            offersData?.map((offer, index) =>
              offer?.offerType === 1 ? (
                <div
                  key={index}
                  className={`mx-0 border p-1 mb-1 rounded ${
                    cartItems === offer?.qty
                      ? "border-success"
                      : "border-danger"
                  }`}
                >
                  <Row className="jusalign-item-center">
                    <Col md={8} xs={8} sm={8}>
                      <b
                        className={`${
                          cartItems === offer?.qty ? "text-success" : ""
                        }`}
                      >
                        🎁🎉 {offer?.name}
                      </b>
                    </Col>
                    <Col md={4} xs={4} sm={4}>
                      {!couponTypeStatus && (
                        <Button
                          onClick={async () => {
                            const apiResp = await dispatch(
                              applyCartOffer({ qty: cartItems })
                            );
                            if (apiResp) {
                              couponAmountStatus(apiResp.payload?.offer?.rate, true);
                              setCouponQty(apiResp.payload?.offer?.qty);
                              localStorage.removeItem("code");
                              localStorage.setItem("couponoffer", false);
                              handleClose();
                            }
                          }}
                          disabled={cartItems === offer?.qty ? false : true}
                          variant={
                            cartItems !== offer?.qty ? "secondary" : "success"
                          }
                          className="px-2 py-1 float-right"
                        >
                          Apply
                        </Button>
                      )}
                      {couponTypeStatus && couponQty === offer?.qty && (
                        <Button
                          onClick={() => couponAmountStatus(0, false)}
                          variant="danger"
                          className="px-2 py-1 float-right"
                        >
                          X
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <p
                        className={`mb-0 ${
                          cartItems === offer?.qty
                            ? "text-success"
                            : "text-danger"
                        }`}
                      >
                        Apply coupon and save ₹69.80
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <p
                        className={`mb-0 ${
                          cartItems === offer?.qty
                            ? "text-success"
                            : "text-danger"
                        }`}
                      >
                        {offer?.description}
                      </p>
                    </Col>
                  </Row>
                </div>
              ) : null
            )}
          <Row>
            <Col md={12}>
              <Button variant="danger" className="w-100 mt-2" onClick={handleClose}>
                Close
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Offers;

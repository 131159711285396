import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCategoriesFrontEnd } from "../../reducers/frontEndReducer";
import { getProducts } from "../../reducers/commonReducer";

const Categories = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategoriesFrontEnd());
  }, []);
  const { getCatsFrontEndData } = useSelector((state) => state.frontEndReducer);
  return (
    <>
      <section className="cat-text header-main">
        <div className="container-fluid">
          <Row>
            {!!getCatsFrontEndData &&
              getCatsFrontEndData?.map((cat, index) => {
                return (
                  <Col
                    xs={4}
                    sm={4}
                    md={1}
                    lg={1}
                    className="pt-2 pb-2 align-center cat-main"
                    key={index}
                  >
                    <div className="cat-tab">
                      <Link
                        to={`/shop/${cat?.slug}?ref_=${cat?.id}`}
                        onClick={() =>
                          dispatch(
                            getProducts({
                              categories: [cat?.id],
                            })
                          )
                        }
                      >
                        <img src={cat?.image} />
                        <p>{cat?.name}</p>
                      </Link>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
      </section>
    </>
  );
};

export default Categories;

import React from "react";
import Footer from "../Sub/Footer";
import webBanner from "../../images/webBanner.jpg";
import CommonHeader from "../Sub/CommonHeader";
import Banner from "../Sub/Banner";
import { Col, Row } from "react-bootstrap";

const About = () => {
  let width = window.innerWidth;
  return (
    <>
      <CommonHeader />
      <div>
        <Banner bannerImg={webBanner} />
      </div>
      <div className="container-fluid">
        <div className={`page-main ${width >= 468 && "p-5"}`}>
          <Row>
            <h1 className="mb-4">ABOUT US</h1>
            <p>
            Our company (<b>SHREE HIRA COMPUTER & COMMUNICATION</b>) started in 2003.
            </p>
            <p>
            Broadly we are working in following items.
            </p>
            <p className="mb-0">1. Computer Parts</p>
            <p className="mb-0">2. CCTV</p>
            <p className="mb-0">3. Antivirus/Software</p>
            <p className="mb-0">4. Import/refurbished desktop & Laptop</p>
            <p>
            We have more than <b>500</b> different products available.
            </p>
            <p>
            We are supplying material in <b>PAN INDIA</b> 
            </p>
            <p>With this website we want to give <b>minimum</b> price directly/indirectly to our dealers.</p>
            <p><b>Our GST Details & Address</b></p>
            <p>
            SHREE HIRA COMPUTER and COMMUNICATIONS<br/>

            SHOP NO.10, LAJPAT RAI MARKET<br/>

            OPP.ELITE CINEMA, RAILWAY ROAD<br/>

            Hisar -125001
            </p>
            <p><b>Contact no:- 9728622667</b></p>
            <p><b>GST NO. 06AFCPB0886L1ZH</b></p>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;

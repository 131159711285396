import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {deleteUserAddress, getUserAddress} from "../../../reducers/commonReducer";
import UpdateAddress from "./UpdateAddress";
import Footer from "../../Sub/Footer";
import CommonHeader from "../../Sub/CommonHeader";
import { Link, useNavigate } from "react-router-dom";
import { addUserAddress, userDashboard } from "../../../const";
import Offers from "./Offers";
import { orderCreate } from "../../../reducers/orderDetailsReducer";
import Coupon from "./Coupon";

const UserAddress = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const amount = queryParameters.get("amount");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [coupon, setCoupon] = useState();
  const [couponStatus, setCouponStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);
  useEffect(() => {
    setLoading(true);
    dispatch(getUserAddress());
    setLoading(false);
  }, []); 
  //select address
  const handleSelect = (address, index) => {
    setSelectedAddressIndex(index);
  };
  //coupon amount and Qty
  const updateCouponAmountStatus = (amount, status) => {
    setCoupon(amount);
    setCouponStatus(status);
  };
  const { getUserAddressList } = useSelector((state) => state.commonReducer);
  const discount = (coupon / 100) * amount
  return (
    <>
      <CommonHeader />
      <div className="container-fluid">
        <Row>
          <Col md={12}>
            <p className="f-18 mt-2 mb-0 text-center"><strong>Order Summary: <span><b>{amount}/-</b></span></strong></p>
            {
              discount > 1 && <div className="border py-2 mb-2 rounded">
                <h6 className="text-success text-center">🎁🎉Congratulations you got <b>{coupon}%</b> Discount.🎁🎉</h6>
                <h6 className="text-info text-center mb-0">Total Payable Amount: <span className="text-danger"><b>{amount}-{discount} = {amount - discount}/-</b></span></h6>
              </div>
            }
            <Offers couponRate={coupon} couponTypeStatus= {couponStatus} couponAmountStatus = {updateCouponAmountStatus}/>
            <hr/>
          </Col>
        </Row>
        <Row className="">
          <Container>
            <div className="section__head d-flex justify-content-between mb-40">
              <div className="section__title">
                <h2>
                  <b>My Address</b>
                </h2>
              </div>
              <Link to={addUserAddress}>
                <Button className="theme-btn mt-2">Add New Address</Button>
              </Link>
            </div>
          </Container>
        </Row>
        {loading ? (
          <div className="align-center">
            <Spinner animation="border" />
          </div>
        ) : (
          <Row>
            {getUserAddressList?.map((address, index) => (
              <Col key={index} md={4} className="mt-2 mb-2">
                  <Container onClick={() => handleSelect(address, index)} className={selectedAddressIndex === index ? 'bg-success text-white rounded' : ''}>
                    <div className="row pb-2 cursor-pointer shadow pt-2">
                        <Row className="align-item-center">
                          <Col md={8} sm={8} xs={8} className="d-flex">
                          <input 
                              type="radio" 
                              name="addressRadio" 
                              className="m-1"
                              checked={selectedAddressIndex === index} 
                              onChange={() => handleSelect(address, index)} 
                            />
                            <p className="mb-0">
                              <strong>
                                {address?.firstName + " " + address?.lastName}
                              </strong>
                            </p>
                          </Col>
                          <Col md={4} sm={4} xs={4} className="text-end">
                            <UpdateAddress
                              title={"Update Address"}
                              firstName={address?.firstName}
                              lastName={address?.lastName}
                              addressline1={address?.addressline1}
                              addressline2={address?.addressline2}
                              city={address?.city}
                              zipcode={address?.zipcode}
                              state={address?.state}
                              phone={address?.phone}
                              email={address?.email}
                              indexId={index}
                              id={address?._id}
                            />
                            <Button
                              variant="danger"
                              className="rounded-circle"
                              onClick={async (e) => {
                                const apiResp = await dispatch(
                                  deleteUserAddress({ id: address?._id, index })
                                );
                                if(apiResp.payload.status){
                                  dispatch(getUserAddress())
                                }
                              }}
                            >
                              <i className="bi bi-trash3-fill"></i>
                            </Button>
                          </Col>
                        </Row>
                        <address className="mb-0">
                          <b>
                            {address?.addressline1 +
                              " " +
                              address?.addressline2}
                            <br />{" "}
                            {address?.city +
                              "-" +
                              address?.zipcode +
                              "," +
                              address?.state}
                          </b>
                        </address>
                        <p className="mb-0">
                          <strong>Phone: </strong>
                          {address?.phone}
                        </p>
                        <p className="mb-0">
                          <strong>Email: </strong>
                          {address?.email}
                        </p>
                    </div>
                  </Container>
              </Col>
            ))}
          </Row>
        )}
        {(selectedAddressIndex !== null) && <Row>
          <Col md={12} className="mb-2">
            <Button onClick={async() => {
             const apiResp = await dispatch(orderCreate(
              {
                addressIndex: selectedAddressIndex, 
                cartoffer:couponStatus,
                couponoffer: (/true/).test(localStorage.getItem("couponoffer")), 
                code: localStorage.getItem("code"),
              }))
             if(apiResp){
              setTimeout(() => {
                navigate(userDashboard) 
                },1500)
             }
            }} className="theme-btn w-100">Pay Now <i className="bi bi-arrow-right"></i></Button>
          </Col>
        </Row>}
      </div>
      <Footer />
    </>
  );
};

export default UserAddress;

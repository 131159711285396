import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { getPackage } from "../../reducers/commonReducer";

const schema = yup.object().shape({
  name: yup.string().required(),
  length: yup.string().required(),
  width: yup.string().required(),
  height: yup.string().required(),
  status: yup.boolean().required(),
});

function PackageAction({
  title,
  popUpTitle,
  id,
  name,
  length,
  width,
  height,
  status,
  api,
}) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  return (
    <>
      <Button className="bg-thememain" onClick={handleShow}>
        {title}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{popUpTitle}</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            const apiResp = await dispatch(api(values));
            if(apiResp?.payload?.status){
              dispatch(getPackage());
              resetForm({ values: "" });
              handleClose();
            }
          }}
          initialValues={{
            id,
            name,
            length,
            width,
            height,
            status,
          }}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={12} sm={12}>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4} sm={4}>
                  <Form.Group>
                    <Form.Label>Length</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Length"
                      name="length"
                      value={values.length}
                      onChange={handleChange}
                      isInvalid={!!errors.length}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.length}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} sm={4}>
                  <Form.Group>
                    <Form.Label>Width</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Width"
                      name="width"
                      value={values.width}
                      onChange={handleChange}
                      isInvalid={!!errors.width}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.width}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} sm={4}>
                  <Form.Group>
                    <Form.Label>Height</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Height"
                      name="height"
                      value={values.height}
                      onChange={handleChange}
                      isInvalid={!!errors.height}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.height}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={6}>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={handleChange}
                    name="status"
                    className="form-control bg"
                    value={values.status}
                    isInvalid={!!errors.status}
                  >
                    <option value="">Select Status</option>
                    <option value={true}>Active</option>
                    <option value={false}>Inactive</option>
                  </Form.Control>
                </Col>
              </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
                <Button variant="danger" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default PackageAction;

import React, { useState, useEffect } from 'react';
import axios from 'axios';

const IpAddressComponent = () => {
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGeolocation = async () => {
      try {
        let geolocationResponse;
        
        // Try ipinfo.io first
        try {
          geolocationResponse = await axios.get('https://ipinfo.io/json?token=218500d621f07d');
        } catch (err) {
          // If ipinfo.io fails, try ipapi.co
          geolocationResponse = await axios.get('https://ipapi.co/json');
        }
        
        setLocation(geolocationResponse.data);
      } catch (err) {
        console.error('Error fetching geolocation data:', err);
        setError('Unable to fetch geolocation data');
      }
    };

    fetchGeolocation();
  }, []);

  return (
    <div>
      {location ? (
        <div>
          <p>IP Address: {location.ip}</p>
          <p>Country: {location.country_name || location.country}</p>
          <p>Region: {location.region_name || location.region}</p>
          <p>City: {location.city}</p>
          <p>Latitude and Longitude: {location.loc || `${location.latitude}, ${location.longitude}`}</p>
          <p>Postal Code: {location.postal}</p>
          <p>Timezone: {location.timezone}</p>
        </div>
      ) : (
        <p>Loading geolocation data...</p>
      )}
      {error && <p>Error: {error}</p>}
    </div>
  );
};

export default IpAddressComponent;

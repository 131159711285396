import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideMenu from "../../Components/SideMenu";
import { addOffer, getOffersForAdmin, updateOffer} from "../../reducers/commonReducer";
import { home } from "../../const";
import OffersAction from "./OffersAction";


const OffersList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate(home);
    }
    dispatch(getOffersForAdmin())
  }, []);

  const { offersForAdminData } = useSelector((state) => state.commonReducer);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid">
      <OffersAction
          title={"Add Offer"}
          popUpTitle="Add New Offer"
          api={addOffer}
        />
      <table className="table mt-2">
          <thead className="normalplace">
            <tr>
              <th className="over-col-size">Sr.</th>
              <th className="over-col-size">Name</th>
              <th className="over-col-size">Description</th>
              <th className="over-col-size">Rate</th>
              <th className="over-col-size">OfferType</th>
              <th className="over-col-size">Qty</th>
              <th className="over-col-size">Status</th>
              <th className="over-col-size">Action</th>
            </tr>
          </thead>
          <tbody>
            {!!offersForAdminData &&
              offersForAdminData?.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr key={data?._id}>
                      <td>{index + 1}.</td>
                      <td>{data?.name}</td>
                      <td>{data?.description}</td>
                      <td>{data?.rate}%</td>
                      <td>{data?.offerType}</td>
                      <td>{data?.qty}</td>
                      <td><b>{data?.status ? <span className="text-success">Active</span> : <span className="text-danger">Inactive</span>}</b></td>
                      <td>
                        <OffersAction
                          title={<i className="bi bi-pencil-square"></i>}
                          popUpTitle = "Update Offer"
                          api = {updateOffer}
                          id = {data?.id}
                          name = { data?.name}
                          description = {data?.description}
                          rate = {data?.rate}
                          status = {data?.status}
                          offerType = {data?.offerType}
                          qty={data?.qty}
                        />
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default OffersList;

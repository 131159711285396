import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Col } from "react-bootstrap";
import dummy from "../../images/dummy.jpeg";
import { getCountProduct } from "../../reducers/frontEndReducer";
import { calculateImageSize, productRating } from "../../const";
import CustomizeProduct from "./CustomizeProduct";

const OnSaleProducts = ({ productsDetails, title }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const width = useRef(window.innerWidth);
  const productImageRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  useEffect(() => {
    const { cheight, cwidth } = calculateImageSize(width.current, 4);
    dispatch(
      getCountProduct({
        counter: 8,
        width: cwidth,
        height: cheight
      })
    );
  }, [width.current]);
  useEffect(() => {
    if (productImageRef.current) {
      const width = productImageRef.current.offsetWidth;
      const height = productImageRef.current.offsetHeight;
      setDimensions({ width, height });
    }
  }, []);

  return (
    <>
      {!!productsDetails?.list && productsDetails?.list?.length > 0 && (
        <section className="onsell__area pt-15 pb-40">
          <div className="row">
            <div className="col-xl-12">
              <div className="section__head d-flex justify-content-between">
                <div className="section__title">
                  <h3>
                    <span>{title}</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {productsDetails?.list.map((data, index) => {
              return (
                <Col md={3} sm={6} xs={6} className="p-0" key={index}>
                  <div className="product-grid card">
                    <div className="product-image" ref={productImageRef}>
                      <div className="card-img-actions ">
                        <Link
                          to={`/product/${data?.slug}`}
                          className="text-default mb-2"
                          data-abc="true"
                          title={data?.name}
                        >
                           {(data?.productType === "customize") ? <CustomizeProduct coloums={4} customizeDetails={data?.customizeDetails}/> : <img
                          src={
                            data?.images
                            ? data?.images[data?.defaultImage]
                              : dummy
                          }
                          className="img-fluid product-small-img"
                          alt={data?.name}
                        />
                          }
                        </Link>
                      </div>
                      {!!data?.sale && (
                        <span className="product-discount-label">
                          -
                          {(
                            (100 * (data?.mrp - data?.sale)) /
                            data?.mrp
                          ).toFixed(2)}
                          %
                        </span>
                      )}
                      <ul className="product-links">
                        <li>
                          <NavLink to={`/product/${data?.slug}`}>
                            <i className="bi bi-search"></i>
                          </NavLink>
                        </li>
                        {/* <li
                          onClick={async () => {
                            localStorage.getItem("x-auth-token") &&
                              (await dispatch(
                                userProductLikedData[data?.id]
                                  ? userAddWishList({
                                      productId: data?.id,
                                      status: false,
                                    })
                                  : userAddWishList({
                                      productId: data?.id,
                                      status: true,
                                    })
                              ));
                            localStorage.getItem("x-auth-token") &&
                              (await dispatch(userProductLikedList()));
                          }}
                        >
                          {!!userProductLikedData && (
                            <NavLink to="" className={"heartImg"}>
                              {userProductLikedData[data?.id] === true ? (
                                <img src={darkheart} />
                              ) : (
                                <img src={lightheart} />
                              )}
                            </NavLink>
                          )}
                        </li> */}
                      </ul>
                    </div>
                    <div className="product-content">
                      <button
                        className="add-to-cart"
                        onClick={() => navigate(`/product/${data?.slug}`)}
                      >
                        {data?.productType === "customize" ? `Personalize & Customize` : `Add to Cart`}
                      </button>
                      <Link
                      to={`/product/${data?.slug}`}
                      className="text-default mb-2"
                      data-abc="true"
                    >
                      <div className="card-body text-center">
                        <div className="mb-mbl-0 product-name-box">
                          <b className="product-name">{title}</b>
                        </div>
                        <hr />
                        <p className="mb-1">{productRating(5)}</p>
                        <h3 className="mb-0 font-weight-semibold product__price text-success">
                          <span className="discount-price text-danger">
                            Rs.{data?.mrp}
                          </span>{" "}
                          Rs.{data?.sale}
                        </h3>
                      </div>
                    </Link>
                    </div>
                  </div>
                </Col>
              );
            })}
          </div>
        </section>
      )}
    </>
  );
};

export default OnSaleProducts;

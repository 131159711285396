import React, { Fragment, useEffect, useRef } from "react";
import Features from "../Sub/Features";
import Footer from "../Sub/Footer";
import OnSaleProducts from "../Sub/OnSaleProducts";
import WebSlider from "../Sub/WebSlider";
import Review from "../Sub/Review";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {catWiseLatestProducts, getHomePageSetting, siteReview} from "../../reducers/commonReducer";
import Header from "../Sub/Header";
import Categories from "../Sub/Categories";
import SingleProduct from "../Sub/SingleProduct";
import { Link } from "react-router-dom";
import { calculateImageSize, shop } from "../../const";

const Home = () => {
  const dispatch = useDispatch();
  const width = useRef(window.innerWidth);
  useEffect(() => {
    const { cheight, cwidth } = calculateImageSize(width.current, 4);
    dispatch(
      catWiseLatestProducts({
        width: cwidth,
        height: cheight
      })
    );
  }, [width.current]);
  useEffect(() => {
    dispatch(getHomePageSetting());
    dispatch(siteReview())
  }, []);
  const { getHomePageSettingList, catWiseLatestProductsList, catWiseLatestProductsId, siteReviewList } = useSelector((state) => state.commonReducer);
  const {getCountProductData} = useSelector((state) => state.frontEndReducer);
  const formatString = (str) => {
    return str
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <>
      <Header />
      <Categories />
      {/* <CommonHeader /> */}
      {!!getHomePageSettingList?.home?.show_main_slider && (
        <div className="container-fluid mt-3 mbl-margin-0">
          <WebSlider />
        </div>
      )}
      {!!getHomePageSettingList?.home?.showwebfeatures && (
        <Features mobileHide={"mbl-hide"} />
      )}
      {/* <-----Cat Wise Product-----> */}
      <Container>
        {
          !!catWiseLatestProductsList && Object.entries(catWiseLatestProductsList).map(([cat, products]) => {
            const formattedCat = formatString(cat);
            return(
              <Fragment>
                <Row>
                  <Col md={12}>
                    <div className="section__head d-md-flex justify-content-between mt-3">
                      <div className="section__title">
                        <h3>{formattedCat}</h3>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  {
                    products?.map((item, index) => (
                      <Col md={3} xs={6} sm={6} key={index}>
                        <SingleProduct title={item?.name} productType={item?.productType} mrp={item?.mrp} sale={item?.sale} slug={item?.slug} productImg={item?.productImg} defaultImage={item?.defaultImage} images={item?.images} customizeDetails={item?.customizeDetails}/>
                      </Col>
                    ))
                  }
                </Row>
                <Row className="text-center mb-2">
                  <Link to={`${shop}/${cat}?ref_=${catWiseLatestProductsId?.[cat]}`}><Button className="theme-btn w-100">View All <i className="bi bi-arrow-right"></i></Button></Link>
                </Row>
              </Fragment>
            )
          })
        }
      </Container>
      {/* <-----Cat Wise Product end-----> */}
      {!!getHomePageSettingList?.home?.showlatestproduct && (
        <Container>
          <OnSaleProducts
            productsDetails={getCountProductData}
            title={"Latest Products"}
          />
        </Container>
      )}
      {!!getHomePageSettingList?.home?.review && <Review siteReviewList={siteReviewList}/>}
      <Footer />
    </>
  );
};

export default Home;

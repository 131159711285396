import React, { Fragment, useEffect } from "react";
import { Row, Col, Table, Container, Button } from "react-bootstrap";
import printer from "../../images/printer.png";
import { useDispatch, useSelector } from "react-redux";
import { singleOrder } from "../../reducers/orderDetailsReducer";
import moment from "moment";
import { Link } from "react-router-dom";
import { orders, product } from "../../const";

function AdminOrderDetail(props) {
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const orderId = queryParameters.get("orderid");
  useEffect(() => {
    orderId && dispatch(singleOrder({ id: orderId }));
  }, []);
  const { singleOrderData } = useSelector((state) => state.orderDetailReducer);
  console.log("singleOrderData", singleOrderData);
  return (
    <>
      <Container className="mb-1">
        <Row>
          <Col>
            <Link to={orders}>
              <h5>
                <i className="bi bi-arrow-left"></i>{" "}
                <b className="text-black">{singleOrderData?.orderid}</b>
              </h5>
            </Link>
            <p className="mb-0">
              {moment(singleOrderData?.createdAt).format(
                "MMMM Do YYYY, h:mm a"
              )}{" "}
              from Online Store
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            {/* order details */}
            <div className="border rounded p-2">
              <Button variant="success" className="px-2 py-1">
                <i class="bi bi-truck"></i> Fulfilled (
                {singleOrderData?.totalquantity})
              </Button>
              <div className="border rounded mt-2 p-2">
                <p className="mb-0">Fulfilled</p>
                <p className="mb-0">
                  {moment(singleOrderData?.createdAt).format(
                    "MMMM Do YYYY, h:mm a"
                  )}
                </p>
                {singleOrderData?.items &&
                  singleOrderData?.items?.map((item, index) => {
                    return (
                      <Fragment key={index}>
                        <hr />
                        <Row>
                          <Col md={1}>
                            <img
                              src={item?.mergeimg}
                              alt={item?.productId?.name}
                              loading="lazy"
                            />
                          </Col>
                          <Col md={7}>
                            <p className="mb-0 text-black">
                              <b>{item?.productId?.name}</b>
                            </p>
                            <p className="mb-0 text-black">
                              <b>SKU</b>: ???
                            </p>
                            {item?.userimg?.length > 0 && <p className="text-black mb-0">
                              <b>- You Image:</b>
                              {item?.userimg?.map((userimages, imgIndex) => (
                                <Link
                                  key={imgIndex}
                                  to={userimages?.imageUrl}
                                  target="_blank"
                                >
                                  <Button className="px-1 py-0 m-1 theme-btn">
                                    <i class="bi bi-eye"></i>
                                  </Button>
                                </Link>
                              ))}
                            </p>}
                            {item?.inputFields?.length > 0 && <>
                              <p className="text-black mb-0">
                              <b>- Input Fields:</b>
                            </p>
                            {item?.inputFields?.map((inputs, inputIndex) => {
                              return (
                                <Fragment key={inputIndex}>
                                  <p className="mb-0">
                                    <strong>-{inputs?.name}</strong>:{" "}
                                    <b className="text-black">
                                      {inputs?.value}
                                    </b>
                                  </p>
                                </Fragment>
                              );
                            })}
                            </>}
                            <p className="text-black mb-0">
                              <b>- ProductUrl:</b>
                              {
                                <Link
                                  to={`${product}/${item?.productId?.slug}`}
                                  target="_blank"
                                >
                                  <Button className="px-1 py-0 m-1 theme-btn">
                                    <i class="bi bi-eye"></i>
                                  </Button>
                                </Link>
                              }
                            </p>
                            <p className="text-black mb-0">
                              <b>- Image:</b>
                              {
                                <Link to={item?.mergeimg} target="_blank">
                                  <Button className="px-1 py-0 m-1 theme-btn">
                                    <i class="bi bi-eye"></i>
                                  </Button>
                                </Link>
                              }
                            </p>
                          </Col>
                          <Col md={2}>
                            <p className="mb-0 text-black">
                              <b>
                                ₹ {item?.sale} <i class="bi bi-x"></i>{" "}
                                {item?.quantity}
                              </b>
                            </p>
                          </Col>
                          <Col md={2}>
                            <p className="mb-0 text-black">
                              <b>₹ {item?.sale * item?.quantity}</b>
                            </p>
                          </Col>
                        </Row>
                      </Fragment>
                    );
                  })}
              </div>
            </div>
            {/* Payment details */}
            <div className="border rounded p-2 mt-4">
              <Button variant="secondary" className="px-2 py-1">
                <i class="bi bi-bookmark-check-fill"></i> Paid
              </Button>
              <div className="border rounded mt-2 p-2">
                <Row>
                  <Col md={2}>Subtotal</Col>
                  <Col md={7}>
                    <p>{singleOrderData?.totalquantity} items</p>
                  </Col>
                  <Col md={3}>
                    <p>₹ {singleOrderData?.totalmrpprice}</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>Shipping</Col>
                  <Col md={7}>
                    <p>
                      Free Shipping (Cash on Delivery) (0.0 kg: Items 0.0 kg,
                      Package 0.0 kg)
                    </p>
                  </Col>
                  <Col md={3}>
                    <p>??</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>Taxes</Col>
                  <Col md={7}>
                    <p>IGST (18%) (Included)</p>
                  </Col>
                  <Col md={3}>
                    <p>??</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <b className="text-black">Total</b>
                  </Col>
                  <Col md={7}></Col>
                  <Col md={3}>
                    <p className="text-black">
                      <b>??</b>
                    </p>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col md={2}>
                    <b className="text-black">Paid</b>
                  </Col>
                  <Col md={7}></Col>
                  <Col md={3}>
                    <p className="text-black">
                      <b>??</b>
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col md={4}>
          {/* customer Details */}
            <div className="border rounded p-2">
                <h6 className="text-black"><b>Customer</b></h6>
                <p className="mb-0"><strong>{`${singleOrderData?.user?.address?.[singleOrderData?.user?.defaultAddress]?.firstName} ${singleOrderData?.user?.address?.[singleOrderData?.user?.defaultAddress]?.lastName}`}</strong></p>
                <p>Order : ??</p>
                <h6 className="text-black"><b>Contact information</b></h6>
                <p className="mb-0"><strong><i class="bi bi-envelope"></i> {singleOrderData?.user?.address?.[singleOrderData?.user?.defaultAddress]?.email}</strong></p>
                <p><strong><i class="bi bi-phone-fill"></i> {singleOrderData?.user?.address?.[singleOrderData?.user?.defaultAddress]?.phone}</strong></p>
                <h6 className="text-black"><b>Shipping address</b></h6>
                <p className="mb-0 text-black">{`${singleOrderData?.shippingAddress?.firstName} ${singleOrderData?.shippingAddress?.lastName}`}</p>
                <p className="mb-0 text-black">{singleOrderData?.shippingAddress?.addressline1}</p>
                {singleOrderData?.shippingAddress?.addressline2 && <p className="mb-0 text-black">{singleOrderData?.shippingAddress?.addressline2}</p>}
                <p className="mb-0 text-black">{singleOrderData?.shippingAddress?.city} - {singleOrderData?.shippingAddress?.zipcode}</p>
                <p className="mb-0 text-black">{singleOrderData?.shippingAddress?.state}</p>
                <p className="mb-0 text-black"><i class="bi bi-phone-fill"></i> {singleOrderData?.shippingAddress?.phone}</p>
                <p className="text-black"><i class="bi bi-envelope"></i> {singleOrderData?.shippingAddress?.email}</p>
                <Link target="_blank" to={`https://www.google.com/maps/search/${singleOrderData?.shippingAddress?.addressline1} ${singleOrderData?.shippingAddress?.addressline2}, ${singleOrderData?.shippingAddress?.city}, ${singleOrderData?.shippingAddress?.zipcode}, ${singleOrderData?.shippingAddress?.state}`}> <i className="bi bi-geo-alt"></i> View Map</Link>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AdminOrderDetail;

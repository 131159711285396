import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { Col, Form, Row } from 'react-bootstrap';
import moment  from 'moment';
import {filterPdfSettingCreate, getOrderPdfFilters } from '../../reducers/commonReducer';
const schema = yup.object().shape({
  name: yup.string().required(),
});

function FilterSettingAction({name, startDate, endDate}) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChangeDate = (status, setFieldValue) => {
    // YYYY-MM-DDTHH:mm:ss
    if (status === "true") {
      const todayStart = moment().startOf('day').format('YYYY-MM-DD');
      const currentTime = moment().format('YYYY-MM-DD');
      setFieldValue('startDate', todayStart);
      setFieldValue('endDate', currentTime);
    } else if (status === "false") {
      const yesterdayStart = moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD');
      const yesterdayEnd = moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD');
      setFieldValue('startDate', yesterdayStart);
      setFieldValue('endDate', yesterdayEnd);
    }
  };

  return (
    <>
      <Button variant="success" onClick={handleShow}>
      <i class="bi bi-pen-fill"></i>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Setting Update</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body> */}
        <Formik
          validationSchema={schema}
          onSubmit={async (values) => {
            const apiResp = await dispatch(filterPdfSettingCreate(values));
            if (apiResp?.payload?.status) {
              dispatch(getOrderPdfFilters());
              handleClose();
            }
          }}
          initialValues={{
            name, startDate, endDate
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={12} sm={12}>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Start Date"
                      name="startDate"
                      value={values.startDate}
                      onChange={handleChange}
                      isInvalid={!!errors.startDate}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.startDate}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="End Date"
                      name="endDate"
                      value={values.endDate}
                      onChange={handleChange}
                      isInvalid={!!errors.endDate}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.endDate}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12} sm={12}>
                  <Form.Label>Day</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      handleChange(e);
                      handleChangeDate(e.target.value, setFieldValue);
                    }}
                    name="status"
                    className="form-control"
                    value={values.status}
                    isInvalid={!!errors.status}
                  >
                    <option value="">Select Day</option>
                    <option value={true}>Today</option>
                    <option value={false}>Yesterday</option>
                  </Form.Control>
                </Col>
              </Row>
              <Modal.Footer>
              <Button variant="success" type="submit" onClick={handleClose}>
                Save
              </Button>
              <Button variant="danger" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
            </Form>
          )}
        </Formik>
        {/* </Modal.Body> */}
        
      </Modal>
    </>
  );
}

export default FilterSettingAction;
import React, { useEffect, useState } from "react";
import { Button, Row, Form, InputGroup, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import SideMenu from "../../Components/SideMenu";
import {
  addUser,
  editProfileByAdmin,
  getAllStates,
  getCategories,
  getRoles,
  getUsers,
  resetUsersList,
} from "../../reducers/commonReducer";
import UserAction from "./UserAction";

const Users = () => {
  const userType = useLocation().pathname.split("/").filter(Boolean).pop();
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetUsersList());
    let timeOut = setTimeout(() => {
      dispatch(
        getUsers({
          search: search,
          slug:
            (userType === "vendors" && "vender") ||
            (userType === "users" && "customer") ||
            (userType === "checker" && "checker") ||
            (userType === "manager" && "manager"),
        })
      );
    }, 800);
    return () => clearTimeout(timeOut);
  }, [search, userType]);
  useEffect(() => {
    dispatch(getAllStates());
    dispatch(getCategories());
    dispatch(getRoles());
  }, []);
  const { getUsersData, usersBlank } = useSelector(
    (state) => state.commonReducer
  );
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid">
        <Row className="mb-1">
          <Col md={3}>
            <InputGroup>
              <InputGroup.Text id="inputGroup-sizing-default">
                <i className="bi bi-search"></i>
              </InputGroup.Text>
              <Form.Control
                aria-label="Default"
                placeholder="Search anything..."
                aria-describedby="inputGroup-sizing-default"
                onChange={(e) => setSearch(e.target.value)}
              />
            </InputGroup>
          </Col>
          <Col md={2} className="float-right">
            <UserAction title="Add" api={addUser} />
          </Col>
        </Row>
        <table className="table mt-4 w-100">
          <thead>
            <tr>
              <th>Personal Info</th>
              <th>Address</th>
              <th>Role</th>
              {userType === "vendors" && <th>Category</th>}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!!getUsersData &&
              getUsersData?.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr key={data?.id}>
                      <td>
                        <p className="mb-0">
                          <b>Name: </b>
                          <b
                            className={`${data?.spacialuser && `text-success`}`}
                          >
                            {data?.firstName + " " + data?.lastName}
                          </b>
                        </p>
                        <p className="mb-0">
                          <b>Email: </b>
                          {data?.email}
                        </p>
                        <p className="mb-0">
                          <b>Mobile: </b>
                          {data?.phone}
                        </p>
                        <p className="mb-0">
                          <b>
                            Status:{" "}
                            {data?.status === true ? (
                              <span className="text-success">Active</span>
                            ) : (
                              <span className="text-danger">Inactive</span>
                            )}
                          </b>
                        </p>
                      </td>
                      <td>
                        <p className="mb-0">
                          <b>Name: </b>
                          {data?.firstName + " " + data?.lastName}
                        </p>
                        <p className="mb-0">
                          <b>Address: </b>
                          {`${
                            data?.address?.addressline1
                              ? data.address.addressline1
                              : ""
                          } ${
                            data?.address?.addressline2
                              ? data.address.addressline2
                              : ""
                          }`}
                        </p>
                        <p className="mb-0">
                          <b>PIN Code: </b>
                          {data?.address?.zipcode}
                        </p>
                        <p className="mb-0">
                          <b>City: </b>
                          {data?.address?.city}
                        </p>
                        <p className="mb-0">
                          <b>State: </b>
                          {data?.address?.state}
                        </p>
                      </td>
                      <td>{data?.role?.name}</td>
                      {userType === "vendors" && (
                        <td>
                          {data?.category?.map((cat, index) => (
                            <span key={index}>{cat?.name}, </span>
                          ))}
                        </td>
                      )}
                      <td>
                       {userType === "users" && <Link to={`/orders/${data?.id}`}>
                          <Button variant="success">Orders</Button>
                        </Link>}
                        <UserAction
                          id={data?.id}
                          firstName={data?.firstName}
                          lastName={data?.lastName}
                          phone={data?.phone}
                          secondPhone={data?.secondPhone}
                          email={data?.email}
                          role={data?.role?.id}
                          city={data?.address?.city}
                          state={data?.address?.state}
                          zipcode={data?.address?.zipcode}
                          addressline1={data?.address?.addressline1}
                          addressline2={data?.address?.addressline2}
                          category={data?.category}
                          status={data?.status}
                          api={editProfileByAdmin}
                        />
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
          </tbody>
        </table>
        {!!usersBlank && usersBlank.length > 0 ? (
          <Row>
            <Button
              onClick={() => {
                setPage(page + 1);
                dispatch(
                  getUsers({
                    search: search,
                    page: page + 1,
                  })
                );
              }}
            >
              Load More
            </Button>
          </Row>
        ) : (
          <p className="text-center">
            <b>No record found 😔.</b>
          </p>
        )}
      </div>
    </>
  );
};

export default Users;

import React, { Fragment, useState } from "react";
import Tab from "react-bootstrap/Tab";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "react-bootstrap/Tabs";
import { Col, Row } from "react-bootstrap";
import { productRating } from "../../const";
import moment from "moment";

const ProductTab = (props) => {
  const dispatch = useDispatch();
  const [key, setKey] = useState("Details");
  const { productDesc} = props;
  const { getProductReviewsList } = useSelector((state) => state.orderDetailReducer);
  const setTabs = (k) => {
    setKey(k);
    // key == "Details" &&
    //   dispatch(
    //     getProductReviews({
    //       product: getSingleProductData?.id,
    //     })
    //   );
  };

  return (
    <>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={setTabs}
        className="mb-3"
      >
        <Tab
          eventKey="Details"
          title="Product Description"
          dangerouslySetInnerHTML={{ __html: productDesc }}
        ></Tab>
        <Tab
          eventKey="Review"
          title="Review"
        >
          {!!getProductReviewsList && getProductReviewsList?.map((review, index) => (
            <Fragment key={index}>
              <Row className="mt-2">
                <Col md={12} xs={12} sm={12}><h6><b><i className="bi bi-person-circle"></i> {review?.name}</b></h6></Col>
                <Col md={12} xs={12} sm={12}>{productRating(review?.rating)}</Col>
                <Col md={12} xs={12} sm={12}><p className="mb-0 f-18"><b>{review?.title}</b></p></Col>
                <Col md={12} xs={12} sm={12}><p className="mb-0"><b>{moment(review?.createdAt).format("MMM Do YY")}</b></p></Col>
                <Col md={12} xs={12} sm={12}><p className="mb-0">{review?.description}</p></Col>
              </Row>
              <hr/>
            </Fragment>
          ))}
        </Tab>
      </Tabs>
    </>
  );
};

export default ProductTab;

import React, { useEffect } from "react";
import "./app.css";
import GoToTop from "./Components/GotoTop";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./Pages/LoginPage";
import { ToastContainer, toast } from "react-toastify";
import NotFoundPage from "./Components/NotFoundPage";
import Dashboard from "./Pages/Dashboard";
import Attribute from "./Pages/Attribute/Attribute";
import Categories from "./Pages/Categories/Categories";
import Banners from "./Pages/Banners/Banners";
import Coupons from "./Pages/Coupons/Coupons";
import Setting from "./Pages/Setting/Setting";
import Permissions from "./Pages/Permission/Permissions"; 
import ProductsDetails from "./ComponentsWeb/Sub/ProductsDetails";
import Home from "./ComponentsWeb/Main/Home";
import Reviews from "./Pages/Review/Reviews";
import About from "./ComponentsWeb/Main/About";
import Contact from "./ComponentsWeb/Main/Contact";
import Shop from "./ComponentsWeb/Sub/Shop";
import Checkout from "./ComponentsWeb/Main/Checkout";
import Response from "./ComponentsWeb/Main/Response";
import UserLogin from "./ComponentsWeb/Main/user/UserLogin";
import UserSignup from "./ComponentsWeb/Main/user/UserSignup";
import AddProduct from "./Pages/Products/AddProduct";
import AddUserAddress from "./ComponentsWeb/Main/user/AddUserAddress";
import UserPanel from "./ComponentsWeb/Main/user/UserPanel";
import OrdersList from "./Pages/Orders/OrdersList";
import SocialMediaSetting from "./Pages/SocialMediaSetting";
import ProductsList from "./Pages/Products/ProductsList";
import EmailTemplates from "./Pages/EmailTemplates/EmailTemplates";
import Pages from "./Pages/infoPages/Pages";
import DynamicPages from "./Components/DynamicPages";
import UpdateProduct from "./Pages/Products/UpdateProduct";
import TaxClasses from "./Pages/TaxClasses/TaxClasses";
import ForgotPassword from "./ComponentsWeb/Main/user/forgotpassword/ForgotPassword";
import VerifyOtp from "./ComponentsWeb/Main/user/forgotpassword/VerifyOtp";
import NewPassword from "./ComponentsWeb/Main/user/forgotpassword/NewPassword";
import ShippingChargesList from "./Pages/ShippingCharges/ShippingChargesList";
import UserOrderReper from "./Pages/Orders/UserOrderReper";
import TrackersList from "./Pages/Trackers/TrackersList";
import UsersCartList from "./Pages/UsersCart/UsersCartList";
import OffersList from "./Pages/Offers/OffersList";
import RewardSlabList from "./Pages/RewardSlab/RewardSlabList";
import Users from "./Pages/User/Users"
import IpAddressComponent from "./ComponentsWeb/Sub/IpAddressComponent";
import PaymentOptions from "./ComponentsWeb/Main/PaymentOptions";
import UserAddress from "./ComponentsWeb/Main/user/UserAddress";
import RegisterWithPhone from "./ComponentsWeb/Main/user/RegisterWithPhone";
import ProductLabelList from "./Pages/ProductLabels/ProductLabelList";
import WarehouseList from "./Pages/Warehouse/WarehouseList";
import FileSettingList from "./Pages/FileSettings/FileSettingList";
import OrderFiles from "./Pages/ShippingTool/OrderFiles";
import ProductFieldAction from "./Pages/Products/ProductFieldAction";
import PackageList from "./Pages/Package/PackageList";
// import WebFont from 'webfontloader';

function App() {
  // useEffect(() => {
  //   WebFont.load({
  //     google: {
  //       families: [
  //       "Roboto",
  // "Lato",
  // "Montserrat",
  // "Open Sans",
  // "Oswald",
  // "Poppins",
  // "Sofia",
  // "Trirong",
  // "Audiowide",
  // "Raleway",
  // "Work Sans",
  // "Alegreya",
  // "Libre Baskerville",
  // "Merriweather",
  // "Source Sans 3",
  // "Barlow",
  // "Courier Prime",
  // "Fira Sans",
  // "Noto Sans",
  // "Playfair Display",
  // "PT Serif",
  // "Arimo",
  // "Georgia",
  // "Verdana",
  // "Times New Roman",
  // "Slabo 27px",
  // "Aleo",
  // "Nunito+Sans",
  // "Cursive",
  // "Monospace",
  //       ],
  //     },
  //   });
  // }, []);
  return (
    <>
      <Routes>
        {/* web routes start*/}
        <Route path="/" element={<Home />} /> 
        <Route path="/abc" element={<IpAddressComponent />} />
        <Route path="/response/:orderId/:status" element={<Response />} />
        <Route path="/product/:slug" element={<ProductsDetails />} />
        <Route path="/shop/:slug" element={<Shop />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/about" element={<About />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/login" element={<UserLogin />} />
        <Route path="/signup" element={<UserSignup />} />
        <Route path="/user-address" element={<UserAddress />} />
        <Route path="/add-user-address" element={<AddUserAddress />} />
        <Route path="/user-dashboard" element={<UserPanel />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/:slug" element={<DynamicPages />} />
        <Route path="/phone-verify" element={<RegisterWithPhone />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verify-otp" element={<VerifyOtp />} />
        <Route path="/create-new-password" element={<NewPassword />} />
        {/* web routes end*/}
        <Route path="/admin-login" element={<LoginPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/social-media-setting" element={<SocialMediaSetting />} />
        <Route path="/tax-classes" element={<TaxClasses />} />
        <Route path="/shipping-charges" element={<ShippingChargesList />} />
        <Route path="/offers" element={<OffersList />} />
        <Route path="/trackers" element={<TrackersList />} />
        <Route path="/reward-slab" element={<RewardSlabList />} />
        <Route path="/attributes" element={<Attribute />} />
        <Route path="/users" element={<Users />} />
        <Route path="/vendors" element={<Users />} />
        <Route path="/checker" element={<Users />} />
        <Route path="/manager" element={<Users />} />
        <Route path="/user-cart" element={<UsersCartList />} />
        <Route path="/orders" element={<OrdersList />} />
        <Route path="/order" element={<UserOrderReper />} />
        <Route path="/email-templates" element={<EmailTemplates />} />
        <Route path="/payment-option" element={<PaymentOptions />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/banners" element={<Banners />} />
        <Route path="/coupons" element={<Coupons />} />
        <Route path="/settings" element={<Setting />} />
        <Route path="/permissions" element={<Permissions />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/add-product" element={<ProductFieldAction />} />
        <Route path="/customize-product-img" element={<AddProduct />} />
        <Route path="/product-labels" element={<ProductLabelList />} />
        <Route path="/package" element={<PackageList />} />
        <Route path="/file-setting" element={<FileSettingList />} />
        <Route path="/warehouse" element={<WarehouseList />} />
        <Route path="/product-list" element={<ProductsList />} />
        <Route path="/product-edit/:slug" element={<UpdateProduct />} />
        <Route path="/pages" element={<Pages />} />
        <Route path="/email-templates" element={<EmailTemplates />} />
        <Route path="/order-pdf" element={<OrderFiles />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        theme="colored"
      />
      <GoToTop />
    </>
  );
}
export default App;

import React, { useState } from "react";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getWareHouse, zipcodeCityState } from "../../reducers/commonReducer";

const schema = yup.object().shape({
  status: yup.string().required(),
  name: yup.string().required(),
  phone: yup
    .string()
    .required("Phone number is required")
    .min(10, "Phone number must be exactly 10 digits")
    .max(10, "Phone number must be exactly 10 digits"),
    city: yup.string().required(),
    pin: yup.string().required().min(6, "Phone number must be exactly 6 digits")
    .max(6, "Phone number must be exactly 6 digits"),
    state: yup.string().required(),
    address: yup.string().required(),
    country: yup.string().required(),
    email: yup.string().required(),
    registered_name: yup.string().required(),
    return_address: yup.string().required(),
    return_pin: yup.string().required().min(6, "Phone number must be exactly 6 digits")
    .max(6, "Phone number must be exactly 6 digits"),
    return_city: yup.string().required(),
    return_state: yup.string().required(),
    return_country: yup.string().required(),
});

function WarehouseAction({
  title,
  popUpTitle,
  id,
  phone,
  city,
  name,
  pin,
  state,
  address,
  country,
  email,
  registered_name,
  return_address,
  return_pin,
  return_city,
  return_state,
  return_country,
  status,
  api,
}) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [returnAddress, setReturnAddress] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const { zipcodeCityStateData } = useSelector((state) => state.commonReducer);

  return (
    <>
      <Button className="bg-thememain" onClick={handleShow}>
        {title}
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{popUpTitle}</Modal.Title>
        </Modal.Header>
        <Formik
          enableReinitialize={true}
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            const apiResp = await dispatch(api(values));
            if (apiResp?.payload?.status) {
              dispatch(getWareHouse());
              resetForm({ values: "" });
              handleClose();
            }
          }}
          initialValues={{
            id,
            phone,
            name,
            city: zipcodeCityStateData?.City || city,
            pin: zipcodeCityStateData?.Pincode || pin,
            state: zipcodeCityStateData?.State || state,
            address,
            country,
            email,
            registered_name: returnAddress ? name : registered_name,
            return_address: returnAddress ? address : return_address,
            return_pin: returnAddress ? pin : return_pin,
            return_city: returnAddress ? city : return_city,
            return_state: returnAddress ? state : return_state,
            return_country: returnAddress ? country : return_country,
            status,
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, touched, values, errors }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={4} sm={12}>
                  <Form.Group>
                    <Form.Label>PIN Code</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="PIN Code"
                      name="pin"
                      value={values.pin}
                      onChange={async (e) => {
                        setFieldValue("pin", e.target.value);
                        e.target.value?.length === 6 &&
                          dispatch(
                            zipcodeCityState({
                              zipcode: e.target.value,
                            })
                          );
                      }}
                      isInvalid={!!errors.pin}
                      className={errors.pin && touched.pin && "errors"}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.pin}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} sm={12}>
                  <Form.Group>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="City"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      isInvalid={!!errors.city}
                      className={errors.city && touched.city && "errors"}
                      disabled
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.city}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} sm={12}>
                  <Form.Group>
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="State"
                      name="state"
                      value={values.state}
                      onChange={handleChange}
                      isInvalid={!!errors.state}
                      className={errors.state && touched.state && "errors"}
                      disabled
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.state}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
              <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Country"
                      name="country"
                      value={values.country}
                      onChange={handleChange}
                      isInvalid={!!errors.country}
                      className={errors.country && touched.country && "errors"}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.country}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Address"
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                      isInvalid={!!errors.address}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.address}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4} sm={6}>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} sm={6}>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} sm={6}>
                  <Form.Group>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Phone"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      isInvalid={!!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <InputGroup className="mb-3">
                <InputGroup.Checkbox
                  aria-label="Checkbox for following text input"
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    setReturnAddress(isChecked);
                    if (isChecked) {
                      setFieldValue("registered_name", values.name);
                      setFieldValue("return_address", values.address);
                      setFieldValue("return_pin", values.pin);
                      setFieldValue("return_city", values.city);
                      setFieldValue("return_state", values.state);
                      setFieldValue("return_country", values.country);
                    } else {
                      setFieldValue("registered_name", registered_name);
                      setFieldValue("return_address", return_address);
                      setFieldValue("return_pin", return_pin);
                      setFieldValue("return_city", return_city);
                      setFieldValue("return_state", return_state);
                      setFieldValue("return_country", values.country);
                    }
                  }}
                />
                <Form.Control
                  aria-label="Text input with checkbox"
                  value={"If you need the same return details, please click 'Check'."}
                  disabled
                />
              </InputGroup>
              <h3 className="text-success">Return Details</h3>
              <Row className="mb-3">
                <Col md={4} sm={6}>
                  <Form.Group>
                    <Form.Label>Registered Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Registered Name"
                      name="registered_name"
                      value={values.registered_name}
                      onChange={handleChange}
                      isInvalid={!!errors.registered_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.registered_name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} sm={6}>
                  <Form.Group>
                    <Form.Label>Return Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Return Address"
                      name="return_address"
                      value={values.return_address}
                      onChange={handleChange}
                      isInvalid={!!errors.return_address}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.return_address}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} sm={6}>
                  <Form.Group>
                    <Form.Label>Return Pin</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Return Pin"
                      name="return_pin"
                      value={values.return_pin}
                      onChange={handleChange}
                      isInvalid={!!errors.return_pin}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.return_pin}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4} sm={6}>
                  <Form.Group>
                    <Form.Label>Return City</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Return City"
                      name="return_city"
                      value={values.return_city}
                      onChange={handleChange}
                      isInvalid={!!errors.return_city}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.return_city}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} sm={6}>
                  <Form.Group>
                    <Form.Label>Return State</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Return State"
                      name="return_state"
                      value={values.return_state}
                      onChange={handleChange}
                      isInvalid={!!errors.return_state}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.return_state}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} sm={6}>
                  <Form.Group>
                    <Form.Label>Return Country</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Return Country"
                      name="return_country"
                      value={values.return_country}
                      onChange={handleChange}
                      isInvalid={!!errors.return_country}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.return_country}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4} sm={6}>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select" 
                    onChange={async (e) => {
                      setFieldValue("status", (/true/).test(e.target.value));
                    }}
                    name="status"
                    className="form-control bg"
                    value={values.status}
                    isInvalid={!!errors.status}
                  >
                    <option value="">Select Status</option>
                    <option value={true}>Active</option>
                    <option value={false}>Inactive</option>
                  </Form.Control>
                </Col>
              </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
                <Button variant="danger" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default WarehouseAction;

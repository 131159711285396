import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SideMenu from "../../Components/SideMenu";
import { Button, Row, InputGroup, Col, Form } from "react-bootstrap";
import moment from "moment";
import { productsList, getTaxgsts, resetProductList, deleteProduct } from "../../reducers/commonReducer";
import {getCategoriesFrontEnd} from "../../reducers/frontEndReducer";
import { home } from "../../const";
import ProductImageUpdate from "./ProductImageUpdate";

const ProductsList = () => { 
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState();
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate(home);
    }
    dispatch(getCategoriesFrontEnd());
    dispatch(getTaxgsts());
  }, []);
  useEffect(() => {
    let timeOut = setTimeout(() => {
      dispatch(resetProductList());
      dispatch(
        productsList({
          search: search,
          categories
        })
      );
    }, 800);
    return () => clearTimeout(timeOut);
  }, [search]);
  const { productsListData, productsBlank } = useSelector(
    (state) => state.commonReducer
  );
  const { getCatsFrontEndData } = useSelector(
    (state) => state.frontEndReducer
  );
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid">
      <Row className="mb-2">
          <Col md={5}>
            <InputGroup>
              <InputGroup.Text id="inputGroup-sizing-default">
                <i className="bi bi-search"></i>
              </InputGroup.Text>
              <Form.Control
                aria-label="Default"
                placeholder="Search anything..."
                aria-describedby="inputGroup-sizing-default"
                onChange={(e) => setSearch(e.target.value)}
              />
            </InputGroup>
          </Col>
          <Col md={5}></Col>
          <Col md={2} className="float-right">
            <Button className="float-right" variant="danger" onClick={() => window.location.reload()}>Clear</Button>
          </Col>
        </Row>
        <Row className="mb-1">
          <Col md={3}>
            <Form.Select
                aria-label="Default select example"
                onChange={(e) => {
                  dispatch(resetProductList());
                  dispatch(productsList({categories:e.target.value === "" ? null : [e.target.value]}))
                  setCategories(e.target.value)
                }}
              >
                <option value="">Select Category</option>
                {!!getCatsFrontEndData && getCatsFrontEndData?.map((data, index)=>{
                  return(
                    <option key={index} value={data?.id}>{data?.name}</option>
                  )
                })}
              </Form.Select>
          </Col>
        </Row>
        <table className="table mt-4 table-scrolling ">
          <thead className="normalplace">
            <tr>
              <th className="over-col-size">Sr</th>
              <th className="over-col-size">Name</th>
              <th className="over-col-size">Img</th>
              <th className="over-col-size">DefaultImg</th>
              <th className="over-col-size">CustomizeImg</th>
              <th className="over-col-size">Date</th>
              <th className="over-col-size">MRP</th>
              <th className="over-col-size">Sale</th>
              <th className="over-col-size">Categories</th>
              <th className="over-col-size">ProductType</th>
              <th className="over-col-size">HotSelling</th>
              <th className="over-col-size">Status</th>
              <th className="over-col-size">ShowOnHome </th>
              <th className="over-col-size">Action</th>
            </tr>
          </thead>
          <tbody>
            {!!productsListData &&
              productsListData?.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>
                      <Link to={`/product/${data?.slug}`}>{data?.name}</Link>
                    </td>
                    <td>
                      {data?.productType === "customize" ? <img src={`/${data?.customizeDetails?.[data?.defaultSample]?.productImg}`} alt={data?.name}/> : <img src={`/${data?.images?.[data?.defaultImage]}`} alt={data?.name}/>}
                    </td>
                    <td>
                      {data?.productType !== "customize" && <ProductImageUpdate
                        images={data?.images} 
                        productId={data?.id}
                        dimg={data?.defaultImage}
                      />}
                    </td>
                    <td>
                      {data?.productType !== "normal" && <Link to={`/customize-product-img?id=${data?.id}&type=${data?.productType}`}><Button variant="success"><i class="bi bi-eye-fill"></i></Button></Link>}
                    </td>
                    <td>{moment(data?.createdAt).format("DD/MM/YYYY")}</td>
                    <td>{data?.mrp}/-</td>
                    <td>{data?.sale}/-</td>
                    <td>{data?.categories?.name}</td>
                    <td>{data?.productType}</td>
                    <td>
                      <b>
                        {data?.hotSelling === true ? (
                          <span className="text-success">Active</span>
                        ) : (
                          <span className="text-danger">InActive</span>
                        )}
                      </b>
                    </td>
                    <td>
                      <b>
                        {data?.status === true ? (
                          <span className="text-success">Active</span>
                        ) : (
                          <span className="text-danger">InActive</span>
                        )}
                      </b>
                    </td>
                    <td>
                      <b>
                        {data?.showOnHome === true ? (
                          <span className="text-success">Active</span>
                        ) : (
                          <span className="text-danger">InActive</span>
                        )}
                      </b>
                    </td>
                    <td>
                      <Link to={`/add-product?slug=${data?.slug}`}>
                        <Button><i className="bi bi-pen"></i></Button>
                      </Link>
                      <Button variant="danger" onClick={async() => {
                       const apiResp = await dispatch(deleteProduct(data?.id))
                       if(apiResp?.payload?.status){
                        dispatch(resetProductList());
                        dispatch(productsList())
                       }
                      }}><i class="bi bi-trash3-fill"></i></Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {!!productsBlank && productsBlank.length > 0 ? (
          <Row>
            <Button
              onClick={() => {
                setPage(page + 1);
                dispatch(
                  productsList({
                    search: search,
                    page: page + 1,
                  })
                );
              }}
            >
              Load More
            </Button>
          </Row>
        ) : (
          <p className="text-center">
            <b>No record found 😔.</b>
          </p>
        )}
      </div>
    </>
  );
};

export default ProductsList;
